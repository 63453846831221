import { Form, FormInstance } from 'antd';

import { InputProps } from '../TextInput';
import PhotomitraUploadImage from '../PhotomitraUploadImageOrVideo';
import DocumitraUploadImage from '../DocumitraUploadImageOrVideo';
import PhotomitraOrderUploadImageOrVideo from '../PhotomitraOrderUploadImageOrVideo';
import AboutPageOurTeam from '../AboutPageOurTeam';

interface FileInputProps extends InputProps {
    form: FormInstance;
}

const fileUploadName = {
    DOCUMITRA_FILE: 'documitraFile',
    PHOTOMITRA_FILE: 'photomitraFile',
    PHOTOMITRA_ORDER_FILE: 'photoOrderFile',
    ABOUT_PAGE_OUR_TEAM: 'aboutPageOurTeam',
};

const FileInput = (props: FileInputProps) => {
    const { formInput, index, form } = props;

    const fieldValue = form.getFieldValue(formInput.name) || formInput?.value;
    // const fieldValue = [
    //     'https://documitra-knapster.s3.ap-south-1.amazonaws.com/photomitra-photo-store/Screenshot%20from%202023-12-01%2018-59-15.png',
    // ];
    //eslint-disable-next-line
    console.log('fieldValue', formInput);
    let FileUploadComponent: any;

    switch (formInput.fieldType.name) {
        case fileUploadName.DOCUMITRA_FILE:
            FileUploadComponent = DocumitraUploadImage;
            break;
        case fileUploadName.PHOTOMITRA_FILE:
            FileUploadComponent = PhotomitraUploadImage;
            break;
        case fileUploadName.PHOTOMITRA_ORDER_FILE:
            FileUploadComponent = PhotomitraOrderUploadImageOrVideo;
            break;
        case fileUploadName.ABOUT_PAGE_OUR_TEAM:
            FileUploadComponent = AboutPageOurTeam;
            break;
        default:
            FileUploadComponent = DocumitraUploadImage;
    }

    return (
        <Form.Item
            key={index}
            className='upload text'
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            labelAlign='left'
            colon={formInput.required}
            name={formInput.name}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
            ]}>
            {formInput.labelParagraph ? <div className='label-paragraph'> {formInput.labelParagraph} </div> : null}

            <FileUploadComponent
                name={formInput.name}
                fieldValue={fieldValue}
                form={form}
                fieldType={formInput.fieldType}
            />
        </Form.Item>
    );
};

export default FileInput;
