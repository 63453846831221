/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Card, Collapse, FormInstance, Image, Typography, Space, Button, Tooltip } from 'antd';

import { useState, useEffect } from 'react';

import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import { InputInterface } from '../utils/forms/FormInterface';
import PhotoRemarksModal from './PhotoRemarksModal';
import { fallback } from '../constants/FallbackImage';
import {
    APPLICATION_FORM_PACKAGES,
    APPLICATION_FORM_SERVICES,
    DOCUMENT_ACCEPT_REJECT,
    DOCUMENT_DELETE_BY_MEMBER,
    DOCUMITRA_ORDER_PROCESSED_PDF,
    ORDER_USER,
} from '../constants/EntityName';
import callApi, { uploadImageApiCall } from '../Api/config';
import UserDetails from './UserDetails';
import PaymentDetails from './PaymentDetails';
import { getRole } from '../service/CookieManager';
import { toastError, toastSuccess } from '../service/Toast';
import DocumitraAdditionalDetail from './DocumitraAdditionalDetail';
import { BASE_IMAGE_URL } from '../env';

const { Panel } = Collapse;
const { Title, Text } = Typography;

interface revieworderProps {
    formInput: InputInterface;
    index: number;
    form: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    formDetails?: any;
}

const ReviewDocumitraOrder = (props: revieworderProps) => {
    const { formDetails } = props;
    const [members, setMembers] = useState<any>([]);
    const [activePhoto, setActivePhoto] = useState<any>({});
    const [openAddRemark, setOpenAddRemarks] = useState<boolean>(false);
    const [paymentDetails, setPaymentDetails] = useState<any>({});
    const [userDetails, setUserDetails] = useState<any>({});

    const [pdfData, setPdfData] = useState<any>();
    const [pdfUrl, setPdfUrl] = useState<any>();
    const [memberId, setMemberId] = useState();

    const [loading, setLoading] = useState(true);
    const [serviceName, setServiceName] = useState('');

    const handleDeleteClick = async (id: any, docId: any) => {
        try {
            if (id && docId) {
                const res = await callApi(`${DOCUMENT_DELETE_BY_MEMBER}/${id}`, 'DELETE');

                if (res?.data?.statusCode === 200) {
                    toastSuccess('Document deleted successfully');
                    const _member = [...members];
                    _member[0].processedDocument.url = undefined;
                    setMembers(_member);
                    setLoading(true);
                }
            } else {
                const _member = [...members];
                _member[0].processedDocument.url = undefined;
                setMembers(_member);
                setLoading(true);
            }
        } catch (error) {
            console.error('Error:', error);
            toastError('An error occurred while deleting the document');
        }
    };

    const onDownload = async (fileUrl: any) => {
        if (fileUrl) {
            window.open(fileUrl?.startsWith('https') || !fileUrl ? fileUrl : `${BASE_IMAGE_URL}/${fileUrl}`);
        }
    };

    const getDate = (date: string) => {
        const selectedDate = new Date(date);
        const day = selectedDate.getDate();
        const monthName = selectedDate.toLocaleString('default', { month: 'short' });
        const year = selectedDate.getFullYear();

        const formattedDate = `${day}/${monthName}/${year}`;
        return (
            <Text style={{ marginLeft: '25px' }} strong>
                {formattedDate || '--'}
            </Text>
        );
    };

    const getPhoneNumber = (phone: any) => {
        const phoneArray = phone.split('/');
        return `${phoneArray[0]} ${phoneArray[1]} ${phoneArray[2]}`;
    };

    const getQuestionValue = (question: any) => {
        const inputType = question?.question?.inputType;

        if (inputType === 'file upload' || inputType === 'image upload') {
            return (
                <div
                    style={{
                        display: 'flex',
                        gap: '4px',
                        width: '200px',
                        padding: '6px',
                        borderRadius: '8px',
                        position: 'relative',
                    }}>
                    <Image
                        width={200}
                        src={
                            question?.question?.value?.url?.startsWith('https') || !question?.question?.value?.url
                                ? question?.question?.value?.url
                                : `${BASE_IMAGE_URL}/${question?.question?.value?.url}`
                        }
                        fallback={fallback}
                    />
                    <DownloadOutlined
                        style={{
                            position: 'absolute',
                            fontSize: 18,
                            bottom: '12px',
                            right: '12px',
                            padding: '4px',
                            cursor: 'pointer',
                            backgroundColor: '#00000070',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                        onClick={() => onDownload(question?.question?.value?.url)}
                    />
                </div>
            );
        }
        if (inputType === 'date') {
            return getDate(question?.question?.value);
        }
        if (inputType === 'phone') {
            return getPhoneNumber(question?.question?.value);
        }

        return (
            <Text style={{ marginLeft: '25px' }} strong>
                {question?.question?.value || '--'}
            </Text>
        );
    };

    const getMemberFormData = (member: any) => {
        const { formData } = member;
        if (!formData) {
            return <div>No forms available</div>;
        }
        const memberForm = JSON.parse(formData);
        const { steps } = memberForm;
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                Order ID:
                            </Text>
                            {formDetails?.orderId}
                        </div>
                        <div>
                            <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                Member Name:
                            </Text>
                            {member?.name}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                Age Group:
                            </Text>
                            {member?.ageGroup}
                        </div>
                        <div>
                            <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                Package Name:
                            </Text>
                            {paymentDetails?.packageName}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {formDetails?.applyingFrom && (
                            <div>
                                <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                    Applying From:
                                </Text>
                                {formDetails?.applyingFrom}
                            </div>
                        )}
                        {formDetails?.citizenship && (
                            <div>
                                <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                    Citizenship:
                                </Text>
                                {formDetails?.citizenship}
                            </div>
                        )}
                        {formDetails?.destination && (
                            <div>
                                <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                    Destination:
                                </Text>
                                {formDetails?.destination}
                            </div>
                        )}
                        {formDetails?.serviceId && (
                            <div>
                                <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                    Service Name:
                                </Text>
                                {serviceName}
                            </div>
                        )}
                    </div>
                </div>
                {steps?.map((step: any) => (
                    <div key={step?.id} style={{ marginBottom: '10px' }}>
                        <Text style={{ fontSize: '20px', display: 'flex', justifyContent: 'center' }} strong>
                            {step?.stepTitle}:
                        </Text>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            {step?.stepQuestions?.map((question: any) => (
                                <div
                                    key={question?.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0px',
                                    }}>
                                    <Text type='secondary'>Q. {question?.question?.label}</Text>
                                    {getQuestionValue(question)}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const handleAddRemarks = (photo: any) => () => {
        setActivePhoto(photo);
        setOpenAddRemarks(!openAddRemark);
    };

    const handleDownloadAll = (items: any) => {
        const allUrls: any = [];
        items?.documents?.forEach((doc: any) => {
            doc.photos.forEach((photo: any) => {
                allUrls.push(photo.url);
            });
        });

        const downloadWithDelay = async (url: string, filename: string) => {
            const a = document.createElement('a');
            a.href = url?.startsWith('https') || !url ? url : `${BASE_IMAGE_URL}/${url}`;
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        };

        const getFileExtension = (url: string) => {
            const parts = url.split('.');
            return parts[parts.length - 1].toLowerCase();
        };

        const downloadAll = async () => {
            for (let i = 0; i < allUrls.length; i++) {
                const photoUrl = allUrls[i];
                if (photoUrl) {
                    try {
                        let filename = `file${i + 1}`;
                        const extension = getFileExtension(photoUrl);
                        if (extension === 'jpg' || extension === 'png' || extension === 'pdf') {
                            filename += `.${extension}`;
                        }
                        await downloadWithDelay(photoUrl, filename);
                    } catch (error) {
                        console.error('Download failed for:', photoUrl, error);
                    }
                }
            }
        };

        downloadAll();
    };

    const documentAcceptRejectClick = async (access: any, id: any) => {
        try {
            const payload = { 'approved_status': access };
            const res = await callApi(`${DOCUMENT_ACCEPT_REJECT}/${formDetails?.id}/${id}`, 'PATCH', payload);
            if (res?.data?.statusCode === 200) {
                if (payload.approved_status === true) {
                    toastSuccess('Document Accepted');
                    const acceptIcon = document.getElementById(`${id}-accept-reject`);
                    if (acceptIcon) {
                        acceptIcon.style.backgroundColor = 'lightgreen';
                    }
                } else {
                    toastError('Document Rejected');
                    const rejectIcon = document.getElementById(`${id}-accept-reject`);
                    if (rejectIcon) {
                        rejectIcon.style.backgroundColor = 'salmon';
                    }
                }
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const handleDownloadForm = (member: any) => {
        const { formData } = member;
        if (!formData) {
            return;
        }
        const memberForm = JSON.parse(formData);
        const { steps } = memberForm;
        const printWindow = window.open('', '_blank');

        if (printWindow) {
            printWindow.document.write(`<Your Print Templatehtml><head><title>Form Details</title>`);
            printWindow.document.write('</head><body style="padding: 20px; margin-top: 100px;">');
            printWindow.document.write('<div class="print-template">');
            printWindow.document.write(
                `<div style='font-size: 16px;'>
                    <span style='font-size: 20px; font-weight: 600;'>
                        Order ID:
                    </span> 
                    ${formDetails?.orderId} 
                    <span style='font-size: 20px; margin-left:70px; font-weight: 600;'>
                        Age Group:
                    </span>
                    ${member?.ageGroup}
                </div>`,
            );
            printWindow.document.write(
                `<div style='font-size: 20px;'>
                    <span style='font-size: 20px; font-weight: 600;'>
                        Member Name:
                    </span> 
                    ${member?.name}
                    <span style='font-size: 20px; margin-left:70px; font-weight: 600;'>
                        Package Name:
                    </span>
                    ${paymentDetails?.packageName}
                </div>`,
            );
            printWindow.document.write(
                `
                <div style='font-size: 20px;'>
                    ${
                        formDetails?.applyingFrom
                            ? `<span style='font-size: 20px; font-weight: 600;'>Applying From:</span> ${formDetails.applyingFrom}`
                            : ''
                    }
                </div>
                <div style='font-size: 20px;'>
                    ${
                        formDetails?.citizenship
                            ? `<span style='font-size: 20px; font-weight: 600;margin-top: 50px; '>Citizenship:</span> ${formDetails.citizenship}`
                            : ''
                    }
                </div>
                <div style='font-size: 20px;'>
                    ${
                        formDetails?.destination
                            ? `<span style='font-size: 20px; font-weight: 600;'>Destination:</span> ${formDetails.destination}`
                            : ''
                    }
                </div>
                <div style='font-size: 20px;'>
                    ${
                        formDetails?.serviceId
                            ? `<span style='font-size: 20px; font-weight: 600;'>
                            Service Name:
                        </span> 
                        ${serviceName}`
                            : ''
                    }
                </div>
                
                `,
            );
            for (let i = 0; i < steps.length; i++) {
                let stepQuestions = steps[i]?.stepQuestions;
                printWindow.document.write(
                    `<div style="display: flex; justify-content: center; font-size: 16px; font-weight: 600; margin-top: 50px;">${steps[i]?.stepTitle}:</div>`,
                );
                for (let j = 0; j < stepQuestions?.length; j++) {
                    let stepQues = stepQuestions[j];
                    if (!stepQues) {
                        continue;
                    }
                    printWindow.document.write(`<h5>Q. ${stepQues?.question?.label}</h5>`);
                    if (stepQues?.question?.inputType === 'file upload') {
                        printWindow.document.write(
                            `<img style="width: 200px;" src="${
                                stepQues?.question?.value?.url?.startsWith('https') || !stepQues?.question?.value?.url
                                    ? stepQues?.question?.value?.url
                                    : `${BASE_IMAGE_URL}/${stepQues?.question?.value?.url}`
                            }" alt="Image">`,
                        );
                    } else if (stepQues?.question?.inputType === 'date') {
                        const selectedDate = new Date(stepQues?.question?.value);
                        const day = selectedDate.getDate();
                        const monthName = selectedDate.toLocaleString('default', { month: 'short' });
                        const year = selectedDate.getFullYear();

                        const formattedDate = `${day}/${monthName}/${year}`;
                        printWindow.document.write(`<p style="margin-left: 20px;">${formattedDate || '--'}</p>`);
                    } else if (stepQues?.question?.inputType === 'phone') {
                        const phoneArray = stepQues?.question?.value.split('/');
                        printWindow.document.write(
                            `<p style="margin-left: 20px;">${
                                `${phoneArray[0]} ${phoneArray[1]} ${phoneArray[2]}` || '--'
                            }</p>`,
                        );
                    } else {
                        printWindow.document.write(
                            `<p style="margin-left: 20px;">${stepQues?.question?.value || '--'}</p>`,
                        );
                    }
                    stepQues = null;
                }
                stepQuestions = null;
            }

            printWindow.document.write('</div></body></html>');

            printWindow.document.close();
            printWindow.print();
        }
    };

    const getMemberDocumentsData = (member: any) => {
        if (member?.documents?.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {member?.documents?.map((doc: any) => (
                        <div key={doc?.id}>
                            <Title level={5}>{doc?.name}:</Title>
                            <div style={{ display: 'flex', gap: '10px', flex: 'wrap' }}>
                                {doc?.photos?.map((photo: any) => (
                                    <div
                                        key={photo?.id}
                                        id={`${photo?.id}-accept-reject`}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'end',
                                            gap: '4px',
                                            padding: '6px',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                            backgroundColor:
                                                // eslint-disable-next-line no-nested-ternary
                                                photo?.approved_status !== null
                                                    ? photo?.approved_status === true
                                                        ? 'lightgreen'
                                                        : 'salmon'
                                                    : 'white',
                                        }}>
                                        {photo?.url.slice(-3) === 'pdf' ? (
                                            <iframe
                                                src={`https://docs.google.com/viewer?url=${
                                                    photo?.url?.startsWith('https') || !photo?.url
                                                        ? photo?.url
                                                        : `${BASE_IMAGE_URL}/${photo?.url}`
                                                }&embedded=true`}
                                                frameBorder='0'
                                                height='200px'
                                                width='200px'></iframe>
                                        ) : (
                                            <Image
                                                width={200}
                                                src={
                                                    photo?.url?.startsWith('https') || !photo?.url
                                                        ? photo?.url
                                                        : `${BASE_IMAGE_URL}/${photo?.url}`
                                                }
                                                fallback={fallback}
                                            />
                                        )}

                                        <Space
                                            style={{
                                                marginTop: '4px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                            <Button
                                                style={{
                                                    backgroundColor: '#586f80',
                                                    color: 'white',
                                                    borderRadius: '4px',
                                                    padding: '4px 8px 4px 8px',
                                                }}
                                                onClick={handleAddRemarks(photo)}>
                                                + Add Remarks
                                            </Button>
                                            <Tooltip placement='top' title={'Accept'}>
                                                <CheckCircleOutlined
                                                    onClick={() => documentAcceptRejectClick(true, photo?.id)}
                                                />
                                            </Tooltip>
                                            <Tooltip placement='bottom' title={'Reject'}>
                                                <CloseCircleOutlined
                                                    onClick={() => documentAcceptRejectClick(false, photo?.id)}
                                                />
                                            </Tooltip>

                                            <DownloadOutlined
                                                style={{ fontSize: 18 }}
                                                onClick={() => onDownload(photo?.url)}
                                            />
                                        </Space>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <Button
                        style={{
                            backgroundColor: '#586f80',
                            color: 'white',
                            borderRadius: '4px',
                            padding: '4px 8px 4px 8px',
                        }}
                        onClick={() => handleDownloadAll(member)}>
                        Download All Documents
                    </Button>
                </div>
            );
        }
        return <div>No documents available</div>;
    };

    const getUser = async (userId: string) => {
        try {
            const entityData: any = await callApi(`${ORDER_USER}/${userId}`, 'GET');
            const { isSuccess, data } = entityData;
            if (!isSuccess) {
                // eslint-disable-next-line no-console
                console.error('Something went wrong, please try again');
            }
            setUserDetails(data?.data);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Something went wrong, please try again');
        }
    };
    const getPackage = async (packageId: string) => {
        try {
            const entityData: any = await callApi(`${APPLICATION_FORM_PACKAGES}/${packageId}`, 'GET');
            const { isSuccess, data } = entityData;
            if (!isSuccess) {
                // eslint-disable-next-line no-console
                console.error('Something went wrong, please try again');
            }
            setPaymentDetails((prevState: any) => ({ ...prevState, packageName: data?.data?.name }));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Something went wrong, please try again');
        }
    };

    const handleChange = async (e: any, currentMember: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
 	 formData.append('file', file);
        formData.append('userId', userDetails.id);
        formData.append('serviceName', '');
        formData.append('packageName', paymentDetails.packageName);
        formData.append('orderId', paymentDetails.orderId);
        try {
            const res: any = await uploadImageApiCall(`/application/upload`, formData);
            const { data, isSuccess } = res;

            setPdfUrl(data?.url);
            setPdfData(data);

            if (isSuccess) {
                setMemberId(members[0]?.id);
                const updatedMembers = members?.map((member: any) => {
                    if (member?.id === currentMember?.id) {
                        return { ...member, processedDocument: data };
                    }
                    return member;
                });
                setMembers(updatedMembers);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const updatePdf = async (event: any) => {
        event.preventDefault();

        const { id } = formDetails;
        try {
            if (pdfData) {
                const payload = { ...pdfData, memberId: memberId };
                const res = await callApi(`${DOCUMITRA_ORDER_PROCESSED_PDF}/${id}`, 'PUT', payload);
                if (res?.data?.statusCode) {
                    toastSuccess('Processed PDF updated successfully.');
                }
            } else {
                console.error('pdfData is undefined or null');
            }
        } catch (error) {
            console.error('Error updating PDF:', error);
        }
    };

    useEffect(() => {
        if (!formDetails) {
            return;
        }
        const extractServiceName = async () => {
            try {
                const res = await callApi(`${APPLICATION_FORM_SERVICES}/${formDetails.serviceId}`, 'GET');
                setServiceName(res?.data?.data?.name);
            } catch (error) {
                console.error('Error', error);
            }
        };
        extractServiceName();
        const { members } = formDetails;
        setMembers(members);
        const payment = {
            id: formDetails?.id,
            expedite: formDetails?.expedite, //price
            photoEnhancement: formDetails?.photoEnhancement, //price
            pricingPlan: formDetails?.pricingPlan, //discounted price
            convenienceFee: formDetails?.convenienceFee,
            payableOfferPrice: formDetails?.payableOfferPrice, // totalDiscountedprice + convenience fees
            orderId: formDetails?.orderId,
            trackingId: formDetails?.trackingId,
            expectedDate: formDetails?.expectedDate,
            appliedCoupon: formDetails?.appliedCoupon,
            couponDiscount: formDetails?.couponDiscount,
            paymentDate: formDetails?.paymentDetails?.paymentDate,
            paymentMethod: formDetails?.paymentDetails?.paymentMethod,
            orderDate: formDetails?.createdAt,
            paymentDetails: formDetails?.paymentDetails || {},
        };
        getUser(formDetails?.userId);
        getPackage(formDetails?.packageId);
        setPaymentDetails(payment);
    }, [formDetails]);

    const currentUserRole: any = getRole();

    const handleDownloadClick = () => {
        if (members[0]?.processedDocument?.url) {
            const link = document.createElement('a');

            link.href =
                members[0]?.processedDocument?.url?.startsWith('https') || !members[0]?.processedDocument?.url
                    ? members[0]?.processedDocument?.url
                    : `${BASE_IMAGE_URL}/${members[0]?.processedDocument?.url}`;
            link.setAttribute('download', 'downloaded_file.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <>
            <UserDetails userDetails={userDetails} />
            <PaymentDetails
                formDetails={formDetails}
                paymentDetails={paymentDetails}
                userName={userDetails.name}
                userEmail={userDetails.email}
            />
            {currentUserRole !== 'Accountant' && (
                <div key={userDetails?.id} style={{ marginBottom: '20px' }}>
                    <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
                        <Panel key={userDetails?.id} header={`Members:`}>
                            {members?.map((member: any) => (
                                <Card
                                    key={member?.id}
                                    style={{
                                        marginBottom: '25px',
                                        width: '100%',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }}>
                                    <div>
                                        <Collapse expandIconPosition={'left'} defaultActiveKey={['1']}>
                                            <Panel key={member?.id} header={member?.name}>
                                                <div>
                                                    <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
                                                        <Panel key={member?.id} header={'Form Details:'}>
                                                            {member?.formData ? (
                                                                getMemberFormData(member)
                                                            ) : (
                                                                <div>User hasn't filled the form yet.</div>
                                                            )}
                                                            {member?.formData && (
                                                                <Button
                                                                    style={{
                                                                        backgroundColor: '#586f80',
                                                                        color: 'white',
                                                                        borderRadius: '4px',
                                                                        padding: '4px 8px 4px 8px',
                                                                    }}
                                                                    onClick={() => handleDownloadForm(member)}>
                                                                    Download Form
                                                                </Button>
                                                            )}
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                                <div>
                                                    <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
                                                        <Panel key={member?.id} header={'Documents:'}>
                                                            {getMemberDocumentsData(member)}
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                                <div
                                                    key={`${member?.id}-processedDoc`}
                                                    style={{
                                                        width: '100%',
                                                        padding: '20px',
                                                    }}>
                                                    <DocumitraAdditionalDetail
                                                        memberId={member?.id}
                                                        referenceNumber={
                                                            member?.referenceNumber ? member?.referenceNumber : ' '
                                                        }
                                                        dateOfBirth={member?.dateOfBirth ? member?.dateOfBirth : ' '}
                                                        passportNumber={
                                                            member?.passportNumber ? member?.passportNumber : ' '
                                                        }
                                                        trackingLink={member?.trackingLink ? member?.trackingLink : ' '}
                                                    />

                                                    <Title level={5}>{`Upload processed pdf:`}</Title>
                                                    {member?.processedDocument?.url ? (
                                                        <div style={{ position: 'relative' }}>
                                                            {loading && <div className='loader'></div>}
                                                            <embed
                                                                id='pdf-iframe'
                                                                src={`https://docs.google.com/viewer?url=${
                                                                    (member?.processedDocument?.url?.startsWith('https')
                                                                        ? member.processedDocument.url
                                                                        : `${BASE_IMAGE_URL}/${member?.processedDocument?.url}`) ||
                                                                    (pdfUrl?.startsWith('https')
                                                                        ? pdfUrl
                                                                        : `${BASE_IMAGE_URL}/${pdfUrl}`)
                                                                }&embedded=true`}
                                                                height='500px'
                                                                width='400px'
                                                                // eslint-disable-next-line react/no-unknown-property
                                                                onLoad={() => setLoading(false)}></embed>

                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '4px',
                                                                    right: '4px',
                                                                    display: 'flex',
                                                                    gap: '20px',
                                                                }}>
                                                                <DeleteOutlined
                                                                    style={{
                                                                        backgroundColor: '#00000070',
                                                                        padding: '4px',
                                                                        borderRadius: '4px',
                                                                        fontSize: 20,
                                                                        color: 'white',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleDeleteClick(
                                                                            member?.id,
                                                                            member?.processedDocument?.id,
                                                                        )
                                                                    }
                                                                />
                                                                <DownloadOutlined
                                                                    style={{
                                                                        backgroundColor: '#00000070',
                                                                        borderRadius: '4px',
                                                                        padding: '4px',
                                                                        fontSize: 20,
                                                                        color: 'white',
                                                                    }}
                                                                    onClick={handleDownloadClick}
                                                                />
                                                                <button
                                                                    onClick={(e) => updatePdf(e)}
                                                                    className='ant-btn'
                                                                    style={{
                                                                        backgroundColor: '#586f80',
                                                                        borderRadius: '4px',
                                                                        color: 'white',
                                                                        fontWeight: 600,
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                    }}>
                                                                    Update PDF
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <input
                                                                onChange={(e) => handleChange(e, member)}
                                                                type='file'
                                                                id='avatar'
                                                                name='avatar'
                                                                accept='application/pdf'
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </Card>
                            ))}
                        </Panel>
                    </Collapse>
                </div>
            )}

            <PhotoRemarksModal
                formDetails={formDetails}
                openAddRemark={openAddRemark}
                setOpenAddRemarks={setOpenAddRemarks}
                activePhoto={activePhoto}
            />
        </>
    );
};

export default ReviewDocumitraOrder;
