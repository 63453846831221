/* eslint-disable */
import { errorMsg } from '../constants/messages';

const acceptableImageFormat = ['image/png', 'image/jpg', 'image/jpeg']; // Define acceptable formats

const offerFormData = [
    {
        title: 'Offer Details',
        name: 'title',
        label: 'Offer Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Offer Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    // {
    //     name: 'type',
    //     label: 'Type',
    //     fieldType: {
    //         name: 'select',
    //         options: [
    //             { name: 'Select', value: '' },
    //             { name: 'Flat', value: 'flat' },
    //             { name: 'Percentage', value: 'percentage' },
    //         ],
    //     },
    //     required: false,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Type' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    // {
    //     name: 'discountPercentage',
    //     label: 'Discount Percentage',
    //     fieldType: {
    //         name: 'number',
    //     },
    //     required: false,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Discount Percentage' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    {
        name: 'expiryDate',
        label: 'Offer Expiry Date',
        fieldType: {
            name: 'date',
            format: 'YYYY-MM-DD',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Expiry Date',
            enableSort: false,
            columnType: 'dateAndTime',
        },
        isAccordian: true,
        default: '',
    },
    // {
    //     name: 'bannerImage',
    //     label: 'Banner Image',
    //     fieldType: {
    //         name: 'documitraFile',
    //         accept: acceptableImageFormat,
    //         maxCount: '1',
    //         allowImageCropping: true,
    //     },
    //     placeholder: 'Click here to select',
    //     required: false,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Banner Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
    //     isTableHeader: true,
    //     isAccordian: true,
    // },
    {
        name: 'floatingText',
        label: 'Floating Text',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Floating Text' },
        isAccordian: true,
        default: '',
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Active',
            enableSort: false,
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { offerFormData };
