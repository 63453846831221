/* eslint-disable */
import { USERS_ENTITY } from '../constants/EntityName';
import { errorMsg } from '../constants/messages';
import { allServiceOptions, parametersTypeOptions } from './ApplicationFormMaster/FormServices';
import { getOptions } from './OptionService';

const bucketFormData = [
    {
        title: 'Bucket Title',
        name: 'title',
        label: 'Bucket Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Bucket Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },

    {
        name: 'service',
        label: 'service',
        fieldType: {
            name: 'select',
            options: allServiceOptions,
        },
        placeholder: 'Click here to select Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Service', allowAddRecordClick: false, columnType: 'service' },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'parameters',
        label: 'Parameters',
        fieldType: {
            name: 'dynamicCountriesSelect',
            options: parametersTypeOptions,
        },
        placeholder: 'Click here to select an Parameters',
        required: true,
        errorMsg: errorMsg,
        readonly: true,
        tableMeta: {
            title: 'Parameters',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'parametersDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Active',
            enableSort: false,
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'adminusers',
        label: 'Admin Users',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Users', allowAddRecordClick: false, columnType: 'adminusers' },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
];
const fetchAndSetOptions = async () => {
    try {
        const options = await getOptions(USERS_ENTITY);
        //eslint-disable-next-line
        let tempArr: object[] = [];
        options.map((item: any) => {
            if (item.role !== 'Super Admin') {
                tempArr.push({ ...item, value: item.name });
            }
        });
        //eslint-disable-next-line
        console.log('TempArr:', tempArr);
        if (tempArr.length > 0 && bucketFormData) {
            bucketFormData.map((item: any) => {
                if (item.name === 'adminusers') {
                    const tempObj = item;

                    tempObj.fieldType.options = tempArr;
                    bucketFormData[bucketFormData.indexOf(item)] = tempObj;
                }
            });
            //eslint-disable-next-line
            console.log('Options:', bucketFormData);
        }
    } catch (error) {
        //eslint-disable-next-line
        console.error('Error setting options:', error);
    }
};

fetchAndSetOptions();
export { bucketFormData };
