/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

import useGetResponse from '../../../utils/useGetResponse';
import { InputInterface } from '../../../utils/forms/FormInterface';
import { getIncomingPayload } from '../../../utils/getIncomingPayload';
import { getOutgoingPayload } from '../../../utils/getOutgoingPayload';
import { getAllAccordions } from '../../../utils/forms/formFunctions';
import { formatDate, updateDatamodel, updateFormFields } from '../../../utils/functions';

import { toastError, toastSuccess } from '../../../service/Toast';
import callApi from '../../../Api/config';

import CheckboxGroup from '../../../components/CheckboxGroup';
import TextInput from '../../../components/TextInput';
import Email from '../../../components/Email';
import PinCode from '../../../components/PinCode';
import SelectInput from '../../../components/SelectInput';
import MultiSelectInput from '../../../components/MultiSelectInput';
import NumberInput from '../../../components/NumberInput';
import TimeSlotInput from '../../../components/TimeSlotInput';
import MultipleTextInput from '../../../components/MultipleTextInput';
import MultipleTwinInput from '../../../components/MultipleTwinInput';
import FileInput from '../../../components/FileInput';
import DateInput from '../../../components/DateInput';
import OptionalSelectInput from '../../../components/OptionalSingleSelect';
import TextEditor from '../../../components/TextEditor';
import DrawerInput from '../../../components/DrawerInput';

import { errorMsg, failedMsg } from '../../../constants/messages';
import {
    CATEGORIES_ENTITY,
    ORDER_LIST_ENTITY,
    SUBCATEGORIES_ENTITY,
    DEFECT_TYPES_ENTITY,
    VARIANTS_ENTITY,
    AVAILABLE_DELIVERY_PARTNERS,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ALL_TECHNICIAN_ENTITY,
    ALL_MANAGERS_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    LOCATION_TYPES_ENTITY,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PARAMETERS,
    APPLICATION_FORM_QUESTIONS,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_PACKAGE_FEATURES,
    APPLICATION_FORM_PACKAGE_DOCUMENTS,
    APPLICATION_FORM_PACKAGES,
    APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    FORMS_STEPS,
    FORMS,
    STORE_VARIANT_PRICES_ENTITY,
    STORES_ENTITY,
    STORE_SUBCATEGORY_INVENTORIES_ENTITY,
    STORE_ADDONS_ENTITY,
    ADDONS_ENTITY,
    BULK_ADDONS_ENTITY,
    STORE_BULK_ADDONS_ENTITY,
    STORE_INVENTORIES_ENTITY,
    SUBCATEGORY_INVENTORIES_ENTITY,
    PHOTO_ORDER_APPLICATION,
    DOCUMITRA_ORDER_APPLICATION,
    STEPS_QUESTIONS,
    CONDITIONS_PAGE,
    TERMS_PAGE,
    SERVICE_BASED_FAQ,
    TIME_LINE_HEADING,
    TIME_LINE_BODY,
    COUNTRY,
    SERVICE_TIMELINE,
    USERS_ENTITY,
    PACKAGE_TIMELINE,
    PACKAGE_PROCESS_STEPS,
    PACKAGE_PROCESS,
    COUNTRY_CODE,
    CONTACT_FORM,
} from '../../../constants/EntityName';

import '../entity.scss';
import './style.scss';
import TextArea from '../../../components/TextArea';
import DynamicSelectInput from '../../../components/DynamicSelectInput';
import { acceptableImageFormat } from '../../../constants/FilterDataValues';
import MultipleTwinInputforValidation from '../../../components/MultipleTwinInputforValidation';
import DynamicCheckboxInput from '../../../components/DynamicCheckboxInput';
import FormWithQuestions from '../../../components/FormWithQuestions';
import DynamicShowKeys from '../../../components/DynamicShowKey';
import PackagePlanSelection from '../../../components/PackagePlanSelection/PackagePlanSelection';
import ReviewDocumitraOrder from '../../../components/ReviewDocumitraOrder';
import ReviewPhotoOrder from '../../../components/ReviewPhotoOrder';
import TimelineTable from '../../../components/TimelineTable';
import InformtionNote from '../../../components/InformtionNote';
import DynamicCountrySelect from '../../../components/DynamicCountrySelect';
import { RemoveRolePermission } from '../../../constants/AppConstant';
import { getRole } from '../../../service/CookieManager';
import ServiceAttributeSelection from '../../../components/ServiceAttributeSelection';
import PackageProcess from '../../../components/PackageProcess';
import RefferalUserCard from '../../../components/RefferalUserCard';
import RolePermissions from '../../../components/RolePermissions';
import ConfirmationModal from '../../../components/ConfirmationModal';

export enum UpdateRequestType {
    Put = 'PUT',
    Patch = 'PATCH',
    Post = 'POST',
}

interface AbstractFormProps {
    headerName: string;
    entityName: string;
    nestedEntityName?: string;
    formTemplate: Array<InputInterface>;
    payloadProcessor?: (arg: any, arg2: any, arg3?: any) => any;
    additionalEntityName?: string | { name: string; getAllResponse?: boolean }[];
    entityId?: string;
    isCreateMode?: boolean;
    disabledButton?: { delete?: boolean; update?: boolean; cancel?: boolean };
    formDefaultPayload?: any;
    onSuccessfulUpdate?: (id?: string) => void;
    formHeader?: string | undefined;
    setDrawerVisible?: (props: boolean) => void;
    showDeleteDraft?: boolean;
    table?: React.ReactNode;
    closeTab?: (arg0: string, agr2: boolean) => void;
    updateRequired?: boolean;
    extraPayloadData?: any;
    updateRequestType?: UpdateRequestType;
    apiEndPoint?: string;
    apiMethod?: string;
    additionalButtons?: any[];
    hideCron?: boolean;
    hideMaxAmount?: boolean;
    maxDiscount?: boolean;
}

const AbstractForm = (props: AbstractFormProps) => {
    const {
        entityName,
        isCreateMode,
        formTemplate: initialFormTemplate,
        updateRequired,
        additionalEntityName: incomingAdditionalEntityName,
        extraPayloadData,
        nestedEntityName,
        updateRequestType = UpdateRequestType.Patch,
        additionalButtons,
    } = props;

    const [defaultFormData] = useState(props.formDefaultPayload);
    const [incomingFormData, setIncomingFormData] = useState<any>();
    const [formDetails, setFormDetails] = useState<any>();
    const [formTemplate, setFormTemplate] = useState(initialFormTemplate);
    const [additionalEntityName, setAdditionalEntityName] = useState(incomingAdditionalEntityName);
    const additionalData = useGetResponse(additionalEntityName);
    const [loading, setLoading] = useState(false);
    const [dynamicValidation, setDynamicValidation] = useState();
    const [first, setfirst] = useState();

    const [parameterData, setParameterData] = useState<any>({});

    const statusRef = useRef<string>();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false);

    const currentUserRole: any = getRole();
    let rolePermission = RemoveRolePermission[currentUserRole];

    if (entityName === USERS_ENTITY && currentUserRole === 'Admin Ops') {
        rolePermission = {
            create: true,
            read: false,
            delete: true,
            update: true,
        };
    }

    /**
     * This useEffect is used to update the formTemplate file which is defined in the datamodels folder. It can be required in cases where the options for certain field are not known apriori and are obtained through API call
     */
    useEffect(() => {
        const updateFormTemplate = () => {
            switch (entityName) {
                // case USERS_ENTITY: {
                //     const rolesOptions = additionalData?.[ROLES_ENTITY]?.data?.results;

                //     const check = rolesOptions;

                //     check &&
                //         setFormTemplate((formData: any) =>
                //             formData.map((dataModel: any) => {
                //                 if (dataModel.name === 'role') {
                //                     return {
                //                         ...dataModel,
                //                         fieldType: {
                //                             ...dataModel.fieldType,
                //                             options: rolesOptions.map((item: { id: string; name: string }) => ({
                //                                 value: item.id,
                //                                 name: item.name,
                //                             })),
                //                         },
                //                     };
                //                 }

                //                 return dataModel;
                //             }),
                //         );

                //     break;
                // }

                case APPLICATION_FORM_PACKAGE_PLANS: {
                    const featuresList = additionalData?.[APPLICATION_FORM_PACKAGE_FEATURES]?.data;
                    const documentsList = additionalData?.[APPLICATION_FORM_PACKAGE_DOCUMENTS]?.data;

                    const check = documentsList && featuresList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'documents') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: documentsList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }
                                if (dataModel.name === 'features') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: featuresList?.map((item: { id: string; description: string }) => ({
                                                value: item.id,
                                                name: item.description,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
                case PACKAGE_PROCESS_STEPS: {
                    const packageList = additionalData?.[APPLICATION_FORM_PACKAGES]?.data;

                    const check = packageList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'packages') {
                                    return {
                                        ...dataModel,
                                        // readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: packageList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case USERS_ENTITY: {
                    const countryCodeList = additionalData?.[COUNTRY_CODE]?.data;

                    const check = countryCodeList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'createdAt') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: countryCodeList?.map((item: { code: string; name: string }) => ({
                                                value: item.code,
                                                name: item.code + ' ' + item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case PACKAGE_TIMELINE: {
                    const packageList = additionalData?.[APPLICATION_FORM_PACKAGES]?.data;

                    const check = packageList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'packages') {
                                    return {
                                        ...dataModel,
                                        // readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: packageList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
                case PACKAGE_PROCESS: {
                    const packageList = additionalData?.[APPLICATION_FORM_PACKAGES]?.data;

                    const check = packageList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'packages') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: packageList?.map(
                                                (item: { id: string; name: string }, index: number) => ({
                                                    value: item.id,
                                                    name: `${index + 1}. ${item.name}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case APPLICATION_FORM_PACKAGES: {
                    const servicesList = additionalData?.[APPLICATION_FORM_SERVICES]?.data?.results;
                    const servicesAttributesList = additionalData?.[APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES]?.data;
                    const featuresList = additionalData?.[APPLICATION_FORM_PACKAGE_FEATURES]?.data;
                    const documentsList = additionalData?.[APPLICATION_FORM_PACKAGE_DOCUMENTS]?.data;
                    const countryList = additionalData?.[COUNTRY]?.data;

                    const check = servicesList && servicesAttributesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'serviceAttributes') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesAttributesList?.map(
                                                (item: { id: string; name: string }) => ({
                                                    value: item.id,
                                                    name: item.name,
                                                }),
                                            ),
                                        },
                                    };
                                }
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesList?.map(
                                                (item: { id: string; name: string; parameters: any }) => ({
                                                    value: item.id,
                                                    name: item.name,
                                                    parameters: item.parameters,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                if (dataModel.name === 'citizenship') {
                                    return {
                                        ...dataModel,
                                        isAccordian: isCreateMode ? false : parameterData.citizenship,
                                        readonly: false,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: countryList?.map((item: any) => ({
                                                name: item,
                                                value: item,
                                            })),
                                        },
                                    };
                                }
                                if (dataModel.name === 'destination') {
                                    return {
                                        ...dataModel,
                                        isAccordian: isCreateMode ? false : parameterData.destination,
                                        readonly: false,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: countryList?.map((item: any) => ({
                                                name: item,
                                                value: item,
                                            })),
                                        },
                                    };
                                }
                                if (dataModel.name === 'applyingFrom') {
                                    return {
                                        ...dataModel,
                                        isAccordian: isCreateMode ? false : parameterData.applyingFrom,
                                        readonly: false,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: countryList?.map((item: any) => ({
                                                name: item,
                                                value: item,
                                            })),
                                        },
                                    };
                                }

                                if (dataModel.name === 'serviceAttributes') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case STEPS_QUESTIONS: {
                    const questionsList = additionalData?.[APPLICATION_FORM_QUESTIONS]?.data;

                    const check = questionsList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'question') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: questionsList?.map(
                                                (item: { id: string; label: string; inputType: string }) => ({
                                                    value: item.id,
                                                    name: `${item.label}-${item.inputType}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case PHOTO_ORDER_APPLICATION: {
                    const photoOrders = additionalData?.[PHOTO_ORDER_APPLICATION]?.data?.results;
                    const check = photoOrders;

                    check && setFormTemplate((formData: any) => formData.map((dataModel: any) => dataModel));

                    break;
                }

                case DOCUMITRA_ORDER_APPLICATION: {
                    const documentOrders = additionalData?.[DOCUMITRA_ORDER_APPLICATION]?.data?.results;
                    const check = documentOrders;

                    check && setFormTemplate((formData: any) => formData.map((dataModel: any) => dataModel));

                    break;
                }

                case FORMS: {
                    const packageList = additionalData?.[APPLICATION_FORM_PACKAGES]?.data;

                    const check = packageList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'package') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: packageList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: `${item.id} - ${item.name}`,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case APPLICATION_FORM_SERVICES: {
                    const countryList = additionalData?.[COUNTRY]?.data;

                    const countryData = countryList?.map((countryName: string, index: number) => ({
                        id: index + 1,
                        value: countryName,
                        name: countryName,
                    }));

                    const check = countryData;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'countries') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: countryData?.map((item: { id: string; name: string }) => ({
                                                value: item.name,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SERVICE_PROCESS_DETAILS:
                case SERVICE_PROCESS_STEPS: {
                    const servicesList = additionalData?.[APPLICATION_FORM_SERVICES]?.data?.results;

                    const check = servicesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
                case SERVICE_TIMELINE: {
                    const servicesList = additionalData?.[APPLICATION_FORM_SERVICES]?.data?.results;

                    const check = servicesList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesList?.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SERVICE_PROCESS_SUBTITLE: {
                    const processList = additionalData?.[SERVICE_PROCESS_DETAILS]?.data?.results;

                    const check = processList;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'title') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: processList?.map((item: { id: string; heading: string }) => ({
                                                value: item.id,
                                                name: item.heading,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case DEFECT_TYPES_ENTITY: {
                    const subCategoryOptions = additionalData?.[SUBCATEGORIES_ENTITY]?.data?.results;

                    const check = subCategoryOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'subcategory') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: subCategoryOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
                case LOCATIONS_ENTITY:
                case ASSETS_ENTITY: {
                    const locationTypesOptions = additionalData?.[LOCATION_TYPES_ENTITY]?.data?.results;

                    const check = locationTypesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'locationType') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: locationTypesOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case TIME_LINE_HEADING:
                case SERVICE_BASED_FAQ: {
                    const servicesOptions = additionalData?.[APPLICATION_FORM_SERVICES]?.data?.results;

                    const check = servicesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'service') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: servicesOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case TIME_LINE_BODY: {
                    const timeLineHeadingOptions = additionalData?.[TIME_LINE_HEADING]?.data?.results;

                    const check = timeLineHeadingOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'timeLine') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: timeLineHeadingOptions.map(
                                                (item: { id: string; name: string }) => ({
                                                    value: item.id,
                                                    name: item.name,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case COMPLAINTS_ENTITY: {
                    const defectTypeOptions = additionalData?.[DEFECT_TYPES_ENTITY]?.data?.results;

                    const check = defectTypeOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'defectType') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: defectTypeOptions.map((item: { id: string; name: string }) => ({
                                                value: item.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case CONDITIONS_PAGE: {
                    const termsOptions = additionalData?.[TERMS_PAGE]?.data?.results;

                    const check = termsOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'term') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: termsOptions.map(
                                                (item: { id: string; name: string; asset: any }) => ({
                                                    value: item.id,
                                                    name: `${item.name}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case SUBCATEGORIES_ENTITY: {
                    const categoriesOptions = additionalData?.[CATEGORIES_ENTITY]?.data?.results;

                    const check = categoriesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'category') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: categoriesOptions.map(
                                                (item: { id: string; name: string; asset: any }) => ({
                                                    value: item.id,
                                                    name: `${item.name} - ${item.asset?.name}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case CATEGORIES_ENTITY: {
                    const assetsOptions = additionalData?.[ASSETS_ENTITY]?.data?.results;
                    const managersOptions = additionalData?.[ALL_MANAGERS_ENTITY]?.data?.results;

                    const check = assetsOptions && managersOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'asset') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: assetsOptions.map(
                                                (item: { id: string; name: string; locationType: any }) => ({
                                                    value: item?.id,
                                                    name: `${item.name} - ${item?.locationType.name}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                if (dataModel.name === 'manager') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: managersOptions.map((item: { id: string; name: string }) => ({
                                                value: item?.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }

                case MANAGER_TECHNICIAN_ENTITY: {
                    let allTechnicianOptions = additionalData?.[ALL_TECHNICIAN_ENTITY]?.data?.results;
                    const managersOptions = additionalData?.[ALL_MANAGERS_ENTITY]?.data?.results;

                    if (incomingFormData?.results?.technicians.length && allTechnicianOptions?.length) {
                        allTechnicianOptions = [...incomingFormData.results.technicians, ...allTechnicianOptions];
                    }

                    const check = allTechnicianOptions && managersOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'technicians') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel?.fieldType,
                                            options: allTechnicianOptions.map(
                                                (option: { id: string; name: string; mobile: string }) => ({
                                                    value: option.id,
                                                    name: `${option.name}-${option.mobile}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                if (dataModel.name === 'manager') {
                                    return {
                                        ...dataModel,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: managersOptions.map((item: { id: string; name: string }) => ({
                                                value: item?.id,
                                                name: item.name,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );

                    break;
                }
            }

            const entityNamesArray = entityName.split('/');
            const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];
            const requiredEntityName2 = entityNamesArray[entityNamesArray.length - 2];

            switch (`/${requiredEntityName}`) {
                case STORE_INVENTORIES_ENTITY: {
                    const inventoriesOptions =
                        additionalData?.[`${STORES_ENTITY}/${requiredEntityName2}${SUBCATEGORY_INVENTORIES_ENTITY}`]
                            ?.data?.results;

                    const check = inventoriesOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'inventory') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: inventoriesOptions.map(
                                                (inventory: {
                                                    id: string;
                                                    displayName: string;
                                                    optionDisplayName: string;
                                                }) => ({
                                                    value: inventory.id,
                                                    name: `${inventory.displayName}-${inventory.optionDisplayName}`,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );
                    break;
                }

                case STORE_ADDONS_ENTITY: {
                    const addonOptions = additionalData?.[ADDONS_ENTITY]?.data?.results;

                    const check = addonOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'addon') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: addonOptions.map((addon: { id: string; displayName: string }) => ({
                                                value: addon.id,
                                                name: addon.displayName,
                                            })),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );
                    break;
                }

                case STORE_BULK_ADDONS_ENTITY: {
                    const bulkAddonsOptions = additionalData?.[BULK_ADDONS_ENTITY]?.data?.results;

                    const check = bulkAddonsOptions;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'bulkAddon') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: bulkAddonsOptions.map(
                                                (bulkAddon: { id: string; displayName: string }) => ({
                                                    value: bulkAddon.id,
                                                    name: bulkAddon.displayName,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                if (dataModel.name === 'bulkAddons') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                    };
                                }

                                return dataModel;
                            }),
                        );
                    break;
                }

                case STORE_VARIANT_PRICES_ENTITY: {
                    const variants =
                        additionalData?.[
                            `${STORES_ENTITY}/${requiredEntityName2}${STORE_SUBCATEGORY_INVENTORIES_ENTITY}`
                        ]?.data?.results;

                    const {
                        [`${STORES_ENTITY}/${requiredEntityName2}${STORE_SUBCATEGORY_INVENTORIES_ENTITY}`]: variantData,
                        ...storeInventoriesData
                    } = additionalData;
                    const storeInventories = storeInventoriesData[Object.keys(storeInventoriesData)[0]]?.data?.results;

                    const check = variants && storeInventories;

                    check &&
                        setFormTemplate((formData: any) =>
                            formData.map((dataModel: any) => {
                                if (dataModel.name === 'variant') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: variants.map((inventory: { id: string; displayName: string }) => ({
                                                value: inventory.id,
                                                name: inventory.displayName,
                                            })),
                                        },
                                    };
                                }

                                if (dataModel.name === 'storeInventory') {
                                    return {
                                        ...dataModel,
                                        readonly: !isCreateMode,
                                        fieldType: {
                                            ...dataModel.fieldType,
                                            options: storeInventories.map(
                                                (storeInventory: { id: number; name: string; option: any }) => ({
                                                    value: storeInventory.id,
                                                    name: storeInventory.name,
                                                }),
                                            ),
                                        },
                                    };
                                }

                                return dataModel;
                            }),
                        );
                }
            }
        };

        updateFormTemplate();
    }, [additionalData, entityName, isCreateMode, incomingFormData]);

    /**
     * this function fetches data from API for any specific course/roles etc and sets that as the form field values. This function is not called while creating a new course/role.
     * @param coursesWorkshopResponseId
     */

    const getApiData = async () => {
        setLoading(true);

        try {
            const entityData: any = await callApi(`${props.entityName}/${props.entityId}`, 'GET');

            if (!entityData) {
                toastError('Error fetching API data');
                return;
            }
            const { data } = entityData.data;

            const { applyingFrom, citizenship, destination } = data;

            setParameterData({ applyingFrom, citizenship, destination });

            if (!isCreateMode && entityName === USERS_ENTITY) {
                const formTemplateData = formTemplate.filter((item: any) => item.name !== 'password');
                setFormTemplate(formTemplateData);
            }

            if (entityName === CONTACT_FORM) {
                entityData.data.data.createdAt = formatDate(entityData?.data?.data?.createdAt);
            }

            if (entityData?.status && entityData?.data) {
                setIncomingFormData(entityData?.data?.data);
                const data = getIncomingPayload(props.entityName, entityData?.data?.data);

                setFormDetails(entityData?.data?.data);

                const defaultObj: any = {};

                for (const key in props.formDefaultPayload) {
                    if (key === 'startDate' || key === 'endDate') {
                        defaultObj[key] = data[key] && moment(data[key]);
                    } else {
                        defaultObj[key] = data[key] ?? [];
                    }
                }

                if (entityName === VARIANTS_ENTITY) {
                    form.setFieldsValue(data);
                } else {
                    form.setFieldsValue(defaultObj);
                }
            }

            if (entityName === APPLICATION_FORM_SERVICES && !isCreateMode) {
                const formTemplateData = formTemplate.filter((item: any) => item.name !== 'password');
                setFormTemplate(formTemplateData);
            }

            if (currentUserRole === 'Accountant') {
                const formTemplateData = formTemplate.filter(
                    (item: any) => item.name !== 'status' && item.name !== 'trackingId',
                );

                setFormTemplate(formTemplateData);
            }
        } catch (error) {
            console.error('Error fetching API data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const validEntityId = props.entityId || props.entityId === '';
        if (!props.isCreateMode && validEntityId) {
            getApiData();
        }
    }, [props.isCreateMode, props.entityId]);

    /** To update the datamodel and the selected form values once both additional data and the specific data for this form have been fetched */
    useEffect(() => {
        if (entityName === ORDER_LIST_ENTITY) {
            const availableDeliveryPartners = additionalData?.[AVAILABLE_DELIVERY_PARTNERS]?.data?.results;

            if (availableDeliveryPartners) {
                updateDatamodel({
                    setFormTemplate,
                    incomingFormData,
                    availableDeliveryPartners,
                    entityId: props.entityId as string,
                });

                updateFormFields({ incomingFormData, form });
            } else {
                if (incomingFormData) {
                    const endPointsForDropdownData = [
                        {
                            name: AVAILABLE_DELIVERY_PARTNERS,
                            getAllResponse: true,
                            queryParams: { storeId: incomingFormData?.store?.id },
                        },
                    ];
                    setAdditionalEntityName(endPointsForDropdownData);
                }
            }
        }
    }, [additionalData, entityName, form, incomingFormData, props.entityId]);

    const submitForm = (rawPayload: any) => {
        /**
         * the payloadProcessor function returns the payload in the format which can be sent in the request. If payloadProcessor function is not passed, then the reduce method is used to process the payload as default
         */

        const payload = props.payloadProcessor
            ? props.payloadProcessor(rawPayload, additionalData, incomingFormData)
            : getOutgoingPayload(
                  entityName,
                  rawPayload,
                  additionalData,
                  extraPayloadData,
                  incomingFormData,
                  nestedEntityName,
                  isCreateMode,
              );

        if (
            (entityName === DOCUMITRA_ORDER_APPLICATION || entityName === PHOTO_ORDER_APPLICATION) &&
            payload.trackingId.length === 0
        ) {
            delete payload.trackingId;
        }

        if (entityName === APPLICATION_FORM_PACKAGES && payload.plans) {
            payload.plans = payload.plans.filter((plan: any) => plan !== null && plan !== undefined && plan !== '');
        }

        if (entityName === USERS_ENTITY) {
            const countryCode = payload.createdAt;

            payload.phone = countryCode + '-' + payload.phone;

            delete payload.createdAt;
        }

        if (dynamicValidation === 'country dropdown') {
            payload.options = [
                'India',
                'United States',
                'Canada',
                'United Kingdom',
                'Australia',
                'Germany',
                'France',
                'Japan',
                'Brazil',
                'South Africa',
                'Afghanistan',
                'Albania',
                'Algeria',
                'Andorra',
                'Angola',
                'Antigua and Barbuda',
                'Argentina',
                'Armenia',
                'Austria',
                'Azerbaijan',
                'Bahamas',
                'Bahrain',
                'Bangladesh',
                'Barbados',
                'Belarus',
                'Belgium',
                'Belize',
                'Benin',
                'Bhutan',
                'Bolivia',
                'Bosnia and Herzegovina',
                'Botswana',
                'Brunei',
                'Bulgaria',
                'Burkina Faso',
                'Burundi',
                'Cabo Verde',
                'Cambodia',
                'Cameroon',
                'Central African Republic',
                'Chad',
                'Chile',
                'China',
                'Colombia',
                'Comoros',
                'Congo',
                'Costa Rica',
                'Croatia',
                'Cuba',
                'Cyprus',
                'Czech Republic',
                'Denmark',
                'Djibouti',
                'Dominica',
                'Dominican Republic',
                'East Timor (Timor-Leste)',
                'Ecuador',
                'Egypt',
                'El Salvador',
                'Equatorial Guinea',
                'Eritrea',
                'Estonia',
                'Eswatini',
                'Ethiopia',
                'Fiji',
                'Finland',
                'Gabon',
                'Gambia',
                'Georgia',
                'Ghana',
                'Greece',
                'Grenada',
                'Guatemala',
                'Guinea',
                'Guinea-Bissau',
                'Guyana',
                'Haiti',
                'Honduras',
                'Hungary',
                'Iceland',
                'Indonesia',
                'Iran',
                'Iraq',
                'Ireland',
                'Israel',
                'Italy',
                'Jamaica',
                'Jordan',
                'Kazakhstan',
                'Kenya',
                'Kiribati',
                'Korea, North',
                'Korea, South',
                'Kosovo',
                'Kuwait',
                'Kyrgyzstan',
                'Laos',
                'Latvia',
                'Lebanon',
                'Lesotho',
                'Liberia',
                'Libya',
                'Liechtenstein',
                'Lithuania',
                'Luxembourg',
                'Madagascar',
                'Malawi',
                'Malaysia',
                'Maldives',
                'Mali',
                'Malta',
                'Marshall Islands',
                'Mauritania',
                'Mauritius',
                'Mexico',
                'Micronesia',
                'Moldova',
                'Monaco',
                'Mongolia',
                'Montenegro',
                'Morocco',
                'Mozambique',
                'Myanmar (Burma)',
                'Namibia',
                'Nauru',
                'Nepal',
                'Netherlands',
                'New Zealand',
                'Nicaragua',
                'Niger',
                'Nigeria',
                'North Macedonia',
                'Norway',
                'Oman',
                'Pakistan',
                'Palau',
                'Palestine State',
                'Panama',
                'Papua New Guinea',
                'Paraguay',
                'Peru',
                'Philippines',
                'Poland',
                'Portugal',
                'Qatar',
                'Romania',
                'Russia',
                'Rwanda',
                'Saint Kitts and Nevis',
                'Saint Lucia',
                'Saint Vincent and the Grenadines',
                'Samoa',
                'San Marino',
                'Sao Tome and Principe',
                'Saudi Arabia',
                'Senegal',
                'Serbia',
                'Seychelles',
                'Sierra Leone',
                'Singapore',
                'Slovakia',
                'Slovenia',
                'Solomon Islands',
                'Somalia',
                'South Sudan',
                'Spain',
                'Sri Lanka',
                'Sudan',
                'Suriname',
                'Sweden',
                'Switzerland',
                'Syria',
                'Taiwan',
                'Tajikistan',
                'Tanzania',
                'Thailand',
                'Togo',
                'Tonga',
                'Trinidad and Tobago',
                'Tunisia',
                'Turkey',
                'Turkmenistan',
                'Tuvalu',
                'Uganda',
                'Ukraine',
                'United Arab Emirates',
                'Uruguay',
                'Uzbekistan',
                'Vanuatu',
                'Vatican City',
                'Venezuela',
                'Vietnam',
                'Yemen',
                'Zambia',
                'Zimbabwe',
            ];
        }

        if (dynamicValidation === 'occupation dropdown') {
            payload.options = [
                'Doctor',
                'Teacher',
                'Software Developer',
                'Nurse',
                'Mechanical Engineer',
                'Graphic Designer',
                'Chef',
                'Police Officer',
                'Journalist',
                'Electrician',
                'Architect',
                'Data Scientist',
                'Librarian',
                'Accountant',
                'Artist',
                'Marketing Manager',
                'Firefighter',
                'Pharmacist',
                'Pilot',
                'Social Worker',
                'Fitness Trainer',
                'Translator',
                'Biologist',
            ];
        }

        if (props.isCreateMode) {
            createEntity(payload, props.entityName);
        } else {
            updateEntity(payload);
        }
    };

    const createEntity = async (payload: any, entityName: string) => {
        setLoading(true);

        try {
            const res: any = await callApi(entityName, 'POST', payload);

            if (res?.status) {
                toastSuccess(`${props.headerName} created successfully!`);
                props.onSuccessfulUpdate?.('create');
            } else {
                if (entityName === APPLICATION_FORM_PACKAGES && !payload.plans) {
                    toastError('Both Online Consultation and Ready to Ship cannot be empty!!');
                } else {
                    toastError(
                        `Oops, an error occured! ${props.headerName} creation failed. ${
                            res?.data?.message || failedMsg
                        }`,
                    );
                }
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.headerName} creation failed. Please contact development support team`,
            );
        }
        setLoading(false);
    };

    const updateEntity = async (payload: any) => {
        setLoading(true);

        try {
            const url = props.nestedEntityName || `${props.entityName}/${props.entityId}`;

            const res: any = await callApi(url, updateRequestType, payload);

            if (res?.status) {
                toastSuccess(`${props.formHeader} updated successfully!`);
                props.entityId && props.closeTab?.(props.entityId, true);
            } else {
                toastError(
                    `Oops, an error occured! ${props.formHeader} updation failed. ${res?.data?.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.formHeader} updation failed. Please contact development support team`,
            );
        }

        setLoading(false);
    };

    const onPublish = () => {
        statusRef.current = 'Published';
        form.submit();
    };

    const onUpdate = () => {
        form.submit();
    };

    const onDelete = async () => {
        setLoading(true);

        try {
            const res: any = await callApi(`${props.entityName}/${props.entityId}`, 'DELETE');

            if (res?.status) {
                toastSuccess(`${props.formHeader} deleted successfully!`);
                props.entityId && props.closeTab?.(props.entityId, true);
            } else {
                toastError(
                    `Oops, an error occured! ${props.formHeader} deletion failed. ${res?.data.message || failedMsg}`,
                );
            }
        } catch (err) {
            toastError(
                `Oops, an error occured! ${props.formHeader} deletion failed. Please contact development support team`,
            );
        }

        setLoading(false);
    };

    const handleDeleteClick = () => {
        setOpenModal(true);
    };

    const handleConfirmDelete = async () => {
        await onDelete(); // Perform delete action
        setOpenModal(false);
    };

    const handleCancelDelete = () => {
        setOpenModal(false);
    };

    function getFormElement(formTemplate: InputInterface, i: number) {
        switch (formTemplate?.fieldType?.name) {
            case 'checkboxGroup': {
                return <CheckboxGroup formInput={formTemplate} index={i} form={form} />;
            }
            case 'email': {
                return <Email formInput={formTemplate} index={i} />;
            }
            case 'pinCode': {
                return <PinCode formInput={formTemplate} index={i} />;
            }
            case 'number': {
                return <NumberInput formInput={formTemplate} index={i} />;
            }
            case 'select': {
                return (
                    <SelectInput
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        setDynamicValidation={setDynamicValidation}
                        isCreateMode={isCreateMode}
                    />
                );
            }
            case 'informationNote': {
                return <InformtionNote />;
            }
            case 'serviceTimeline': {
                return (
                    <TimelineTable
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }
            case 'packageProcess': {
                return (
                    <PackageProcess
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        parentForm={form}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }

            case 'dynamicSelect': {
                return (
                    <DynamicSelectInput
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                    />
                );
            }

            case 'packagePlanSelection': {
                return (
                    <PackagePlanSelection
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        isCreateMode={isCreateMode}
                        packageDetails={formDetails}
                    />
                );
            }
            case 'serviceAttributeSelection': {
                return (
                    <ServiceAttributeSelection
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        parentForm={form}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }

            case 'refferalUserCard': {
                return (
                    <RefferalUserCard
                        formInput={formTemplate}
                        index={i}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }

            case 'dynamicShowKeys': {
                return (
                    <DynamicShowKeys
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                    />
                );
            }

            case 'rolePermissions': {
                return (
                    <RolePermissions
                        formInput={formTemplate}
                        setFormData={updateRequired && setFormTemplate}
                        form={form}
                        index={i}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }
            case 'multiSelect': {
                return <MultiSelectInput formInput={formTemplate} form={form} index={i} isCreateMode={isCreateMode} />;
            }
            case 'optionalSingleSelect': {
                return <OptionalSelectInput formInput={formTemplate} index={i} />;
            }
            case 'timeSlot': {
                return <TimeSlotInput formInput={formTemplate} index={i} />;
            }
            case 'multipleTextInput': {
                return (
                    <MultipleTextInput
                        formInput={formTemplate}
                        form={form}
                        entityName={props.nestedEntityName}
                        formHeader={props.formHeader}
                        setLoading={setLoading}
                        index={i}
                    />
                );
            }
            case 'textEditor': {
                return <TextEditor formInput={formTemplate} index={i} />;
            }

            // case 'multipleTwinInput': {
            //     return <MultipleTwinInput formInput={formTemplate} index={i} form={form} isCreateMode={isCreateMode} />;
            // }

            case 'reviewDocumitraOrder': {
                return (
                    <ReviewDocumitraOrder
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }
            case 'reviewPhotoOrder': {
                return (
                    <ReviewPhotoOrder
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        isCreateMode={isCreateMode}
                        photoDetails={formDetails}
                    />
                );
            }
            case 'formWithQuestions': {
                return (
                    <FormWithQuestions
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }
            case 'dynamicCountriesSelect': {
                return (
                    <DynamicCountrySelect
                        additionalData={additionalData}
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        isCreateMode={isCreateMode}
                        formDetails={formDetails}
                    />
                );
            }
            case 'MultipleTwinInputforValidation': {
                return (
                    <MultipleTwinInputforValidation
                        formInput={formTemplate}
                        index={i}
                        form={form}
                        dynamicValidation={dynamicValidation}
                    />
                );
            }

            case 'checkboxType': {
                return (
                    <DynamicCheckboxInput
                        formInput={formTemplate}
                        index={i}
                        dynamicValidation={dynamicValidation}
                        formDetails={formDetails}
                    />
                );
            }

            case 'drawerForm': {
                return (
                    <DrawerInput
                        entityId={props.entityId}
                        formInput={formTemplate}
                        form={form}
                        onSuccessfulUpdate={props.onSuccessfulUpdate}
                        isCreateMode={isCreateMode}
                    />
                );
            }
            case 'file':
            case 'image':
            case 'video': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'documitraFile':
            case 'documitraImage':
            case 'documitraVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'aboutPageOurTeamFile':
            case 'aboutPageOurTeamImage':
            case 'aboutPageOurTeamVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }

            case 'photomitraFile':
            case 'photomitraImage':
            case 'photomitraVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }
            case 'photoOrderFile':
            case 'photoOrderImage':
            case 'photoOrderVideo': {
                return <FileInput formInput={formTemplate} index={i} form={form} />;
            }
            case 'date': {
                return <DateInput formInput={formTemplate} index={i} />;
            }

            case 'textarea': {
                return <TextArea formInput={formTemplate} index={i} />;
            }
            default: {
                return <TextInput formInput={formTemplate} index={i} />;
            }
        }
    }

    const allAccordions = getAllAccordions(formTemplate, getFormElement);

    return (
        <Spin spinning={loading}>
            <ConfirmationModal
                open={openModal}
                message={`Are you sure you want to delete?`}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                onClose={handleCancelDelete}
            />
            <div className={'abstract-form'}>
                <div className='container1'>
                    <div className='form-container'>
                        <Form
                            form={form}
                            scrollToFirstError={true}
                            initialValues={defaultFormData}
                            onFinish={submitForm}>
                            {allAccordions}
                        </Form>
                    </div>
                </div>

                <div className='btn-container'>
                    <div className='publish-btn-container'>
                        <div className='publish-btn'>
                            {!props.isCreateMode && (
                                <Button
                                    style={{
                                        background: `${
                                            rolePermission?.update || props.disabledButton?.update
                                                ? 'rgb(88, 111, 128, 0.5)'
                                                : '#586f80'
                                        } `,
                                        color: 'white',
                                    }}
                                    type='primary'
                                    disabled={rolePermission?.update || props.disabledButton?.update}
                                    onClick={onUpdate}>
                                    Update
                                </Button>
                            )}
                        </div>
                        <div className='publish-btn'>
                            {!props.isCreateMode && (
                                <Button
                                    style={{
                                        background: `${
                                            props.disabledButton?.delete || rolePermission?.delete ? '#ffa8a8' : 'red'
                                        } `,
                                        color: 'white',
                                    }}
                                    disabled={rolePermission?.delete || props.disabledButton?.delete}
                                    onClick={handleDeleteClick}>
                                    Delete
                                </Button>
                            )}

                            {props.isCreateMode && (
                                <Button type='primary' onClick={onPublish} disabled={rolePermission?.create}>
                                    Create
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button
                                // type=''
                                style={{ border: '1px solid #586f80' }}
                                disabled={props.disabledButton?.cancel}
                                onClick={() => {
                                    props.closeTab?.(props.entityId || 'a', false);
                                }}>
                                Cancel
                            </Button>
                        </div>
                        {additionalButtons?.map((button) => {
                            const { getOnClickHandler, text, key, isDisabled } = button;
                            return (
                                <Button
                                    type='primary'
                                    onClick={getOnClickHandler({
                                        entityId: props.entityId,
                                        setLoading,
                                        onRefresh: getApiData,
                                    })}
                                    key={key}
                                    disabled={isDisabled(incomingFormData)}>
                                    {text}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

AbstractForm.defaultProps = {
    isCreateMode: true,
    entityId: null,
    formDefaultPayload: {},
    hidePreview: false,
};

export default AbstractForm;
