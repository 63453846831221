import { errorMsg } from '../constants/messages';

const ContactInfoPageFormData = [
  {
    title: 'Contact Info Details',
    name: 'email',
    label: 'E-mail',
    fieldType: {
      name: 'email',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'E-mail', allowAddRecordClick: true },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'contactNumbers',
    label: 'Contact Numbers',
    fieldType: {
      name: 'multipleTextInput',
      minCount: 1,
      maxCount: 3,
    },
    required: true,
    errorMsg,
    tableMeta: {
      title: 'Contact Numbers',
      columnType: 'contactNumbersMultiple',
    },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'facebook',
    label: 'Facebook Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'Facebook Link', columnType: 'facebookLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'facebookGroup',
    label: 'Facebook Group Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: {
      title: 'Facebook Group Link',
      columnType: 'facebookGroupLink',
    },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'instagram',
    label: 'Instagram Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'Instagram Link', columnType: 'instagramLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'linkedIn',
    label: 'LinkedIn Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'LinkedIn Link', columnType: 'linkedInLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'youtube',
    label: 'YouTube Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'YouTube Link', columnType: 'youtubeLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'whatsappChat',
    label: 'WhatsApp Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'WhatsApp Link', columnType: 'whatsappLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'whatsappGroup',
    label: 'WhatsApp Group Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: {
      title: 'WhatsApp Group Link',
      columnType: 'whatsappGroupLink',
    },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'x',
    label: 'X (Twitter) Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'X Link', columnType: 'xLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'pinterest',
    label: 'Pinterest Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'Pinterest Link', columnType: 'pinterestLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'tiktok',
    label: 'TikTok Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'TikTok Link', columnType: 'tiktokLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'reddit',
    label: 'Reddit Link',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg,
    tableMeta: { title: 'Reddit Link', columnType: 'redditLink' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
];

export { ContactInfoPageFormData };
