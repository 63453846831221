import callApi from '../Api/config';

export const getOptions = async (param: any) => {
    try {
        const response = await callApi(`${param}/?page=1&limit=100`, 'GET');
        return response?.data?.data?.results;
    } catch (error) {
        //eslint-disable-next-line
        console.error('Error fetching options:', error);
        throw error;
    }
};
