import { BUCKET_MANAGEMENT } from '../constants/EntityName';
import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PASSWORD, REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';
import { getOptions } from './OptionService';

type RolesType = { id: string; value: string; name: string }[];

export const rolesOption: RolesType = [
    { id: '1', value: 'Super Admin', name: 'Super Admin' },
    { id: '2', value: 'Admin Agent', name: 'Admin Agent' },
    { id: '3', value: 'Admin Ops', name: 'Admin Ops' },
    { id: '4', value: 'Accountant', name: 'Accountant' },
];

const usersFormData = [
    {
        title: 'User Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: true, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'createdAt',
        label: 'Country Code',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Country Code', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phone',
        label: 'Phone No',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone No', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'password',
        label: 'Password',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [
            {
                pattern: REGEX_PASSWORD,
                message:
                    'Password must contain uppercase character, lowercase character, symbol, and number and of length 8',
            },
        ],
        errorMsg: errorMsg,
        tableMeta: { title: 'Password', columnType: 'password' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'role',
        label: 'Role',
        fieldType: {
            name: 'rolePermissions',
            options: rolesOption,
        },
        placeholder: 'Click here to select an item',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Role', enableSort: false, allowAddRecordClick: false, columnType: 'roleName' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
        readonly: false,
    },
    // {
    //     name: 'bucket',
    //     label: 'Bucket',
    //     fieldType: {
    //         name: 'multiSelect',
    //         options: [], // Placeholder for options
    //     },
    //     required: false,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Bucket', enableSort: false, allowAddRecordClick: false, columnType: 'bucket' },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    //     readonly: true,
    // },
];
//eslint-disable-next-line
const fetchAndSetOptions = async () => {
    try {
        const options = await getOptions(BUCKET_MANAGEMENT);
        //eslint-disable-next-line
        const tempArr = options.map((item: any) => {
            return { name: item.title, value: item.title, id: item.id };
        });
        //eslint-disable-next-line
        if (tempArr.length > 0 && usersFormData) {
            usersFormData.map((item: any) => {
                if (item.name === 'bucket') {
                    const tempObj = item;
                    tempObj.fieldType.options = tempArr;
                    usersFormData[usersFormData.indexOf(item)] = tempObj;
                }
            });
            //eslint-disable-next-line
            console.log('Options:', usersFormData);
        }
    } catch (error) {
        //eslint-disable-next-line
        console.error('Error setting options:', error);
    }
};

// fetchAndSetOptions();

export { usersFormData };
