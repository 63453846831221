/* eslint-disable */
import { errorMsg } from '../constants/messages';

const acceptableImageFormat = ['image/png', 'image/jpg', 'image/jpeg']; // Define acceptable formats

const bannerFormData = [
    {
        title: 'Banner Details',
        name: 'title',
        label: 'Banner Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Banner Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'link',
        label: 'Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Link', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'bannerImage',
        label: 'Banner Image',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Banner Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Active',
            enableSort: false,
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { bannerFormData };
