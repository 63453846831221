/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, DatePicker, Table } from 'antd';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownOutlined, RightCircleOutlined } from '@ant-design/icons';

import { InputInterface, OnAddArguments } from '../../../utils/forms/FormInterface';
import useEffectExcludingMount from '../../../hooks/useEffectExcludingMount';
import allowTableShort from '../../../utils/TableShort';
import DefaultTableHeader from './DefaultTableHeader';
import callApi from '../../../Api/config';
import Dropdown from '../../../components/Dropdown';

import '../entity.scss';
import { AppRoutes } from '../../../constants/AppConstant';
import DownloadData from '../../../components/DownloadData';
import { formatDate } from '../../../utils/functions';
import {
    ABOUT_PAGE_OUR_TEAM,
    ADVANTAGE_PAGE_ENTITY,
    APPLICATION_FORM_SERVICES,
    CONTACT_INFO,
    DOCUMITRA_LOGS,
    DOCUMITRA_ORDER_APPLICATION,
    DOCUMITRA_ORDER_TRANSACTION,
    HOW_IT_WORKS_PAGE_ENTITY,
    PHOTO_ORDER_APPLICATION,
    PHOTO_ORDER_TRANSACTION,
    PHOTOMITRA_ADVANTAGE_PAGE_ENTITY,
    PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY,
    PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY,
    REFFERAL_DETAILS,
    RELATED_ARTICLE,
    REVIEWS_PAGE_ENTITY,
} from '../../../constants/EntityName';
import { BASE_IMAGE_URL } from '../../../env';

type FilterList = any;

interface AbstractLandingProps {
    headerName: string;
    entityName: string;
    tableHeaders: Array<InputInterface>;
    refreshData?: boolean;
    onAdd?: (argument1?: OnAddArguments) => void;
    onSlotClick?: any;
    addName?: string;
    entityId?: string;
    code?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    filtersList?: FilterList[];
    setFiltersList?: React.Dispatch<React.SetStateAction<FilterList[]>>;
    handleEdit?: (userData: any) => void;
    orderStatusQueryParams?: any;
    showDownload?: boolean;
    showFilter?: boolean;
    filterListData?: any;
}

let previousPaginationCount = 0;

const AbstractLanding = (props: AbstractLandingProps) => {
    const { entityName, onSlotClick, disableSearch, filtersList } = props;
    const [apiListingData, setApiListingData] = useState<any>([] as any);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [totalCount, setTotalCount] = useState<number>();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [searchPhoneQuery, setPhoneSearchQuery] = useState<string>();
    const [searchCityQuery, setCitySearchQuery] = useState<string>();
    const [searchStateQuery, setStateSearchQuery] = useState<string>();
    const [filterSearch, setFilterSearch] = useState<any>(null);
    const [disableAddBtn, setDisableAddBtn] = useState(props.disableAdd);

    const [selectedFilter, setSelectedFilter] = useState<Array<{ key: string; value: CheckboxValueType[] }>>([]);

    const filterQuery = (filterQuery: any) => {
        setFilterSearch(filterQuery);
    };
    //eslint-disable-next-line
    console.log('sdakjh', apiListingData);
    const entityNameArrayList = [
        ADVANTAGE_PAGE_ENTITY,
        REVIEWS_PAGE_ENTITY,
        HOW_IT_WORKS_PAGE_ENTITY,
        PHOTOMITRA_ADVANTAGE_PAGE_ENTITY,
        PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY,
        PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY,
        APPLICATION_FORM_SERVICES,
    ];

    const getData = async (paginationEvent = pagination) => {
        let queryParams: any = {};
        let res: any;

        if (props.entityId && props.code) {
            queryParams[props.code] = props.entityId;
        }

        if (filterSearch) {
            queryParams = filterSearch;
        }

        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }

        if (searchQuery) {
            if (entityName === AppRoutes.OrderList) {
                queryParams.orderId = searchQuery;
            } else {
                queryParams.search = searchQuery;
            }
        }

        if (selectedFilter.length) {
            selectedFilter?.forEach((v) => {
                queryParams[v.key] = v.value.join(',');
            });
        }

        setLoading(true);

        if (!queryParams.page || !queryParams.limit) {
            res = await callApi(props.entityName, 'GET');
        } else {
            res = await callApi(props.entityName, 'GET', null, queryParams);
        }

        if (entityName === AppRoutes.ContactForm) {
            res?.data?.data?.results?.forEach((item: any) => {
                item.createdAt = formatDate(item.createdAt);
            });
        }

        if (entityName === DOCUMITRA_ORDER_APPLICATION || entityName === PHOTO_ORDER_APPLICATION) {
            if (res?.data?.data?.results) {
                res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                    paymentStatus: i?.paymentDetails?.paymentStatus,
                    createdAt: formatDate(i?.createdAt),
                }));
                setApiListingData(res?.data?.data?.results);
            }
        }

        if (entityNameArrayList.includes(entityName)) {
            if (res?.data?.data?.results) {
                res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                    iconImage:
                        i?.iconImage?.startsWith('https') || !i?.iconImage
                            ? i?.iconImage
                            : `${BASE_IMAGE_URL}/${i?.iconImage}`,
                }));
                setApiListingData(res?.data?.data?.results);
            }
        }

        if (entityName === ABOUT_PAGE_OUR_TEAM || entityName === RELATED_ARTICLE) {
            if (res?.data?.data?.results) {
                res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                    iconImage: i?.url?.startsWith('https') || !i?.url ? i?.url : `${BASE_IMAGE_URL}/${i?.url}`,
                }));
                setApiListingData(res?.data?.data?.results);
            }
        }

        if (entityName === DOCUMITRA_ORDER_TRANSACTION || entityName === PHOTO_ORDER_TRANSACTION) {
            if (res?.data?.data?.results) {
                res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                    appliedCoupon: i?.appliedCoupon || 'N/A',
                    paymentStatus: i?.paymentDetails?.paymentStatus || '--',
                    transactionId: i?.paymentDetails?.transactionId || '--',
                    paymentProvider: i?.paymentDetails?.provider || '--',
                    amountPaid: i?.paymentDetails
                        ? `${i?.paymentDetails?.currency} ${i?.paymentDetails?.amountPaid}`
                        : '--',
                    paymentDate: i?.paymentDetails?.paymentDate || '--',
                }));
                setApiListingData(res?.data?.data?.results);
            }
        }

        if (entityName === DOCUMITRA_LOGS) {
            if (res?.data?.data?.results) {
                res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                    createdAt: formatDate(i?.createdAt),
                }));
                setApiListingData(res.data.data.results);
            }
        }

        if (entityName === REFFERAL_DETAILS) {
            if (res?.data?.data?.results) {
                res.data.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                    createdAt: formatDate(i?.createdAt),
                }));
                setApiListingData(res.data.data.results);
            }
        }

        if (res?.status && res?.data?.data?.results) {
            const apiData = res?.data?.data;

            if (entityName === CONTACT_INFO && apiData?.results?.length) {
                setDisableAddBtn(true);
            }

            const getTotalCount = () => {
                if ('hasMore' in apiData) {
                    const totalCount = apiData.hasMore === true ? (+apiData.page + 1) * 10 : +apiData.page * 10;

                    return totalCount;
                }

                return res?.data?.data?.totalCount;
            };

            const totalCount = getTotalCount();
            setPaginationCount(totalCount);

            setApiListingData(res.data.data.results);
            setTotalCount(totalCount);
        } else {
            setApiListingData([]);
        }

        setLoading(false);
    };

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const onPageChange = async (event: any) => {
        setPagination(event);
        getData(event);
    };

    const onSummitFilter = (filterName: string, list: CheckboxValueType[]) => {
        const newFilter = selectedFilter.filter((value) => value.key !== filterName);

        if (list.length) {
            newFilter.push({
                key: filterName,
                value: list,
            });
        }
        setSelectedFilter(newFilter);
    };

    useEffectExcludingMount(() => {
        if (searchQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchPhoneQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchCityQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchStateQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (filterSearch !== null) {
            getData({ ...pagination, current: 1 });
        }
    }, [searchQuery, searchPhoneQuery, searchCityQuery, searchStateQuery, filterSearch]);

    useEffectExcludingMount(() => {
        getData();
    }, [props.refreshData]);

    // THis is default api call for first time also
    useEffect(() => {
        getData({ ...pagination, current: 1 });
    }, [selectedFilter, entityName]);

    useEffect(() => {
        if (props.orderStatusQueryParams) {
            onSummitFilter('orderStatus', [props.orderStatusQueryParams]);
        }
    }, [props.orderStatusQueryParams]);

    const onAddClick = (record: any) => {
        if (props.onAdd) {
            props.onAdd(record);
        }
    };

    const getFilter = () => (
        <div className='flex'>
            {filtersList?.map((filter) => {
                switch (filter.filterType) {
                    case 'DatePicker':
                        return (
                            <DatePicker
                                key={filter.filterKey}
                                allowClear
                                suffixIcon={<DownOutlined />}
                                className='date-filter'
                                format='YYYY-MM-DD'
                                name={filter.filterName}
                                placeholder={filter.placeholder}
                                onChange={(_, dateString) => onSummitFilter(filter.key, [dateString])}
                            />
                        );
                    default:
                        return (
                            <Dropdown
                                checkBoxOptions={filter.options}
                                onSubmitFilter={onSummitFilter}
                                filterName={filter.filterName}
                                filterKey={filter.filterKey}
                                key={filter.filterKey}
                            />
                        );
                }
            })}
        </div>
    );

    const handleCheckBoxClick = async (record: any) => {
        //eslint-disable-next-line
        console.log('record', record);
        const temp = { ...record, active: !record.active };
        const res = await callApi('/users/update-status', 'PUT', temp, null);
        //eslint-disable-next-line
        console.log('response from check', res);
        if (res?.data?.isSuccess === 1) {
            getData();
        }
    };

    const columns: any = props.tableHeaders.map((tableMeta: any) => ({
        key: tableMeta.tableKey,
        title: tableMeta.tableLabel,
        dataIndex: tableMeta.tableKey,
        // eslint-disable-next-line no-nested-ternary
        colSpan: tableMeta.type === 'image' ? 0 : tableMeta.type === 'combinedColumn' ? 2 : null,
        onCell: (record: any) => ({
            style: {
                cursor:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'pointer'
                        : 'default',
                textDecoration:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'underline'
                        : '',
            },
            onClick: () => {
                if (tableMeta.allowAddRecordClick) {
                    // This code may be used in future
                    // if (routesEntityArray.includes(entityName)) {
                    //     history.push(`${entityName}/${record?.id}`);
                    // } else {
                    onAddClick(record);
                    // }
                }

                // if (tableMeta.allowAddRedirectOnClicks) {
                //     history.push(`${ORDER_LIST_ENTITY}/${record.order.id}`);
                // }

                if (tableMeta.allowSlotRecordClick) {
                    const tableMetaUrl = tableMeta.url;

                    if (tableMetaUrl) {
                        history.push(`${entityName}/${record?.id}/${tableMetaUrl}`);
                    } else {
                        onSlotClick?.(tableMeta.tableKey, { id: record.id, name: record.name });
                    }
                }
            },
        }),

        sorter: allowTableShort(tableMeta.enableSort, tableMeta.tableKey),

        render: (value: any, record: any) => {
            if (tableMeta.columnType === 'userName') {
                return `${record.firstName} ${record.lastName}`;
            }

            if (tableMeta.columnType === 'fileFormats') {
                return `${record?.fileFormats?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'orderIdHash') {
                return `${record.order.orderIdHash}`;
            }
            if (tableMeta.columnType === 'orderId') {
                return `${record.order.id}`;
            }

            if (tableMeta.columnType === 'countriesName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.countries?.join(', ') || '--'}`;
            }
	   
		 if (tableMeta.columnType === 'transactionName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.user?.name}`;
            }
            if (tableMeta.columnType === 'transactionEmail') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.user?.email}`;
            }

            if (tableMeta.columnType === 'citizenship') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.citizenship || '--'}`;
            }

            if (tableMeta.columnType === 'destination') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.destination || '--'}`;
            }
            if (tableMeta.columnType === 'applyingFrom') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.applyingFrom || '--'}`;
            }

            if (tableMeta.columnType === 'displayName') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return value?.name || '--';
            }
            if (tableMeta.columnType === 'roleName') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return record?.role || '--';
            }
            if (tableMeta.columnType === 'orderUserName') {
                return `${record?.user?.name}`;
            }
            if (tableMeta.columnType === 'orderUserEmail') {
                return `${record?.user?.email}`;
            }

            if (tableMeta.columnType === 'facebookLink') {
                return `${record.socialMediaLinks.facebook} `;
            }
            if (tableMeta.columnType === 'facebookGroupLink') {
                return `${record.socialMediaLinks.facebookGroup} `;
            }
            if (tableMeta.columnType === 'youtubeLink') {
                return `${record.socialMediaLinks.youtube}`;
            }
            if (tableMeta.columnType === 'instagramLink') {
                return `${record.socialMediaLinks.instagram}`;
            }
            if (tableMeta.columnType === 'linkedInLink') {
                return `${record.socialMediaLinks.linkedIn}`;
            }
            if (tableMeta.columnType === 'youtubeLink') {
                return `${record.socialMediaLinks.youtube}`;
            }
            if (tableMeta.columnType === 'whatsappLink') {
                return `${record.socialMediaLinks.whatsappChat}`;
            }
            if (tableMeta.columnType === 'whatsappGrouplink') {
                return `${record.socialMediaLinks.whatsappGroup}`;
            }
            if (tableMeta.columnType === 'checkbox') {
                return <Checkbox onClick={() => handleCheckBoxClick(record)} checked={record?.active} />;
            }

            if (tableMeta.columnType === 'contactNumbersMultiple') {
                return `${record.contactNumbers.join(' | ')}`;
            }

            if (tableMeta.columnType === 'documentsDisplayNameObject') {
                return (
                    record?.documents
                        ?.map((documentDisplayNameObject: { name: string }) => documentDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'featureDisplayNameObject') {
                return (
                    record?.features
                        ?.map(
                            (featuresDisplayNameObject: { description: string }) =>
                                featuresDisplayNameObject?.description,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceAttributesDisplayNameObject') {
                return (
                    record?.serviceAttributes
                        ?.map(
                            (serviceAttributeDisplayNameObject: { name: string }) =>
                                serviceAttributeDisplayNameObject?.name,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'plansDisplayNameObject') {
                return (
                    record?.plans
                        ?.map((planDisplayNameObject: { title: string }) => planDisplayNameObject?.title)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'questionsDisplayNameObject') {
                return (
                    record?.questions
                        ?.map(
                            (questionsDisplayNameObject: { questionText: string }) =>
                                questionsDisplayNameObject?.questionText,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'formStepsDisplayNameObject') {
                return (
                    record?.steps
                        ?.map(
                            (formStepsDisplayNameObject: { stepTitle: string }) =>
                                formStepsDisplayNameObject?.stepTitle,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'parametersDisplayNameObject') {
                // return (
                //     record?.parameters
                //         ?.map((parametersDisplayNameObject: { name: string }) => parametersDisplayNameObject?.name)
                //         ?.join(', ') || '--'
                // );
                return `${record?.parameters?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'packageName') {
                return `${record?.package?.name || '--'}`;
            }

            if (tableMeta.columnType === 'questionName') {
                return `${`${record?.question?.label}-${record?.question?.inputType}` || '--'}`;
            }

            if (tableMeta.columnType === 'displayNameTitle') {
                return `${record?.title?.heading || '--'}`;
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }
            if (tableMeta.columnType === 'packageName') {
                return `${record?.package?.name || '--'}`;
            }
            if (tableMeta.columnType === 'termsName') {
                return `${record?.term?.name || '--'}`;
            }

            if (tableMeta.columnType === 'displayNameObjectArray') {
                return value
                    ?.map(
                        (displayNameObject: { name: string; mobile: string }) =>
                            `${displayNameObject?.name} - ${displayNameObject?.mobile}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'password') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return value?.password || '********';
            }

            if (tableMeta.columnType === 'clickHere') {
                return <RightCircleOutlined />;
            }

            if (tableMeta.columnType === 'date') {
                return moment.utc(value).toString();
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'active') {
                return value ? 'Yes' : 'No';
            }

            if (tableMeta.columnType === 'image') {
                return (
                    <div>
                        {value && (
                            <img
                                src={value?.startsWith('https') || !value ? value : `${BASE_IMAGE_URL}/${value}`}
                                style={{ borderRadius: '50%', height: '30px', width: '30px', overflow: 'hidden' }}
                            />
                        )}
                    </div>
                );
            }

            return value ?? '--';
        },
    }));

    const getHeader = () => (
        <DefaultTableHeader
            headerName={props.headerName}
            totalCount={totalCount}
            countText={props.headerName === 'Experts' ? 'Profiles' : 'Items'}
            onAdd={() => props.onAdd && props.onAdd()}
            setSearchQuery={setSearchQuery}
            setPhoneSearchQuery={setPhoneSearchQuery}
            setCitySearchQuery={setCitySearchQuery}
            setStateSearchQuery={setStateSearchQuery}
            addName={props.addName}
            disableAdd={disableAddBtn}
            disableSearch={disableSearch}
            showDownload={props.showDownload}
            entityName={entityName}
            showFilter={props.showFilter}
            items={props.filterListData}
            filterQuery={filterQuery}
        />
    );

    return (
        <div className='landing-container'>
            {getHeader()}
            {/* This is the dropdown for filter in listing UI */}
            <div className='dropdown-group'>{getFilter()}</div>
            <Table
                columns={columns}
                loading={loading}
                dataSource={apiListingData}
                onChange={onPageChange}
                pagination={{ ...pagination, total: totalCount }}
                scroll={{ x: 'max-content' }}
                style={{ minHeight: '100vh' }}
                bordered
            />
        </div>
    );
};

AbstractLanding.defaultProps = {
    refreshData: false,
};

export default AbstractLanding;
