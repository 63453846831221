import { acceptableImageFormat } from '../../constants/FilterDataValues';
import { errorMsg } from '../../constants/messages';

type AllKeyValuePairType = { id: string; value: string; name: string }[];

export const statusOption: AllKeyValuePairType = [
    { id: '1', value: 'Draft', name: 'Draft' },
    { id: '2', value: 'Active', name: 'Active' },
    { id: '3', value: 'Archived', name: 'Archived' },
];

export const parametersTypeOptions: AllKeyValuePairType = [
    { id: '1', value: 'applyingFrom', name: 'Applying From' },
    { id: '2', value: 'destination', name: 'Destination' },
    { id: '3', value: 'citizenship', name: 'Citizenship' },
];

export const allServiceOptions: AllKeyValuePairType = [
    { id: '1', value: '10', name: 'OCI' },
    { id: '2', value: '11', name: 'Visa' },
    { id: '3', value: '12', name: 'Passport' },
    { id: '4', value: '13', name: 'Renunciation' },
    { id: '5', value: '14', name: 'PAN Card' },
    { id: '6', value: '15', name: 'Visa Extension' },
    { id: '7', value: '16', name: 'Indian Consular Services' },
    { id: '8', value: '17', name: 'EAD Card' },
    { id: '9', value: '18', name: 'Green Card' },
    { id: '10', value: '19', name: 'US Citizenship ' },
    { id: '11', value: '20', name: 'NRI Services' },
    { id: '12', value: '28', name: 'Forms and Formats' },
    { id: '13', value: '30', name: 'Banking' },
];

const ServicesFormData = [
    {
        title: 'Service Details',
        name: 'name',
        label: 'Service Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Service Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'isActive',
        label: 'Is Active',
        placeholder: 'Click here to select an isActive',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Active',
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'serviceImage',
        label: 'Service Image (*.png)',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Service Image', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'iconImage',
        label: 'Service Icon (*.png)',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Service Icon', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'activeIcon',
        label: 'Active Icon (*.png)',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'inactiveIcon',
        label: 'Inactive Icon (*.png)',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'parameters',
        label: 'Parameters',
        fieldType: {
            name: 'dynamicCountriesSelect',
            options: parametersTypeOptions,
        },
        placeholder: 'Click here to select an Parameters',
        required: true,
        errorMsg: errorMsg,
        readonly: true,
        tableMeta: {
            title: 'Parameters',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'parametersDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { ServicesFormData };
