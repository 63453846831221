/* eslint-disable */
/* disbale-ts */
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { BASE_IMAGE_URL, TEST_BASE_IMAGE_URL } from '../env';
import Modal from '@mui/material/Modal';
import { ArrowsAltOutlined } from '@ant-design/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PdfViewer(props: any) {
    const { photo }: any = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    //eslint-disable-next-line
    console.log('qweerttyyy', photo);
    // Handle arrow key presses for page navigation

    return (
        <>
            <div
                className='pdfviewer-container'
                style={{ position: 'relative', display: 'flex' }}
                onClick={() => setOpen(true)}>
                <embed key={photo?.id} src={photo?.data} type='application/pdf' width='100%' height='500px' />
                <button
                    style={{
                        position: 'absolute',
                        right: '20px',
                        bottom: '5px',
                        outline: 'none',
                        border: 'none',
                        fontSize: '20px',
                        borderRadius: '15px',
                        width: '30px',
                    }}
                    onClick={(e: any) => {
                        e.preventDefault();
                        setOpen(true);
                    }}>
                    <ArrowsAltOutlined />
                </button>
            </div>
            <Modal
                className='pdfviewer-container3'
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <div>
                    <embed
                        key={photo?.id}
                        src={photo?.data}
                        type='application/pdf'
                        width={(window.innerWidth * 90) / 100}
                        height='700px'
                    />
                </div>
            </Modal>
        </>
    );
}

export default PdfViewer;
