/* eslint-disable */
import { errorMsg } from '../constants/messages';

const acceptableImageFormat = ['image/png', 'image/jpg', 'image/jpeg']; // Define acceptable formats

const partnerFormData = [
    {
        title: 'Partner Details',
        name: 'title',
        label: 'Partner Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Partner Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'link',
        label: 'Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Link', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'partnerIcon',
        label: 'Partner Icon',
        fieldType: {
            name: 'image',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Partner Icon', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'content',
        label: 'Content',
        fieldType: {
            name: 'textEditor',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Content', allowAddRecordClick: true },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { partnerFormData };
