import { documitraOrderStatusOption } from '../constants/AppConstant';
import { errorMsg } from '../constants/messages';

const PhotomitraOrderTransaction = [
    {
        title: 'Photomitra Order Details',
        name: 'orderId',
        label: 'Order ID',
        fieldType: { name: 'text' },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Order ID', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'transactionId',
        label: 'ID',
        fieldType: { name: 'text' },
        required: false,
        errorMsg: errorMsg,
        readonly: true,
        tableMeta: { title: 'Transaction ID', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
    },
	{
        name: 'FullName',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', columnType: 'transactionName', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },

    {
        name: 'Email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email', columnType: 'transactionEmail', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'FullName',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', columnType: 'transactionName', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },

    {
        name: 'Email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email', columnType: 'transactionEmail', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'amountPaid',
        label: 'Amount',
        fieldType: { name: 'text' },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Net Received (total paid amount)', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'appliedCoupon',
        label: 'Coupon Code',
        fieldType: { name: 'text' },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Coupon Code', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'couponDiscount',
        label: 'Coupon Discount Amount',
        fieldType: { name: 'text' },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Coupon Discount Amount', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'paymentProvider',
        label: 'Payment Gateway',
        fieldType: { name: 'text' },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Payment Gateway', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'paymentStatus',
        label: 'Payment Status',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Payment Status', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'paymentDate',
        label: 'Date of Payment',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Date of Payment', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
];

export { PhotomitraOrderTransaction };
