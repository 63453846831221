// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

.ant-upload-text {
  color: #637f92 !important;
  border: none !important; }

.ant-upload .ant-upload-btn {
  padding: 0 !important;
  border: none !important; }

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #9fb0bb !important; }
`, "",{"version":3,"sources":["webpack://./src/components/FileInput/UploadImageOrVideo/entity.scss"],"names":[],"mappings":"AAAA;EACI,UAAU;EACV,SAAS;EACT,sBAAsB,EAAA;;AAG1B;EACI,yBAAyB;EACzB,uBAAuB,EAAA;;AAG3B;EACI,qBAAqB;EACrB,uBAAuB,EAAA;;AAG3B;EACI,gCAAgC,EAAA","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\n\n.ant-upload-text {\n    color: #637f92 !important;\n    border: none !important;\n}\n\n.ant-upload .ant-upload-btn {\n    padding: 0 !important;\n    border: none !important;\n}\n\n.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {\n    border-color: #9fb0bb !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
