/* eslint-disable no-unused-vars */
import {
    SUBCATEGORIES_ENTITY,
    USERS_ENTITY,
    DEFECT_TYPES_ENTITY,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    CATEGORIES_ENTITY,
    CONTACT_INFO,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PACKAGES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    FORMS,
    PHOTO_ORDER_APPLICATION,
    STEPS_QUESTIONS,
    PROCESSED_PHOTO_ENTITY,
    CONDITIONS_PAGE,
    SERVICE_BASED_FAQ,
    PHOTO_STORE_PLAN,
    TIME_LINE_HEADING,
    TIME_LINE_BODY,
    SERVICE_TIMELINE,
    PACKAGE_TIMELINE,
    PACKAGE_PROCESS_STEPS,
    PACKAGE_PROCESS,
    ADVANTAGE_PAGE_ENTITY,
    REVIEWS_PAGE_ENTITY,
    HOW_IT_WORKS_PAGE_ENTITY,
    PHOTOMITRA_ADVANTAGE_PAGE_ENTITY,
    PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY,
    PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY,
    ABOUT_PAGE_OUR_TEAM,
    RELATED_ARTICLE,
} from '../constants/EntityName';
import { BASE_IMAGE_URL } from '../env';
import { TEST_BASE_IMAGE_URL } from '../env';

// It is hard-coded data and needs to be updated whenever the id of Categories change on backend
export const categoryIds: { [key: string]: string } = {
    fresh: '1',
    kitchen: '2',
};

const imageKeyList = ['activeIcon', 'iconImage', 'inactiveIcon', 'serviceImage'];

export const getOutgoingPayload = (
    entityName: string,
    rawPayload: any,
    additionalData: any,
    extraPayloadData?: any,
    incomingFormData?: any,
    nestedEntityName?: string,
    isCreateMode?: boolean,
) => {
    const testImageUrl = BASE_IMAGE_URL;
    switch (entityName) {
        case USERS_ENTITY: {
            return Object.entries(rawPayload).reduce(
                (acc: any, [key, value]: any) =>
                    // if (key === 'role') {
                    //     return { ...acc, [key]: { id: value } };
                    // }
                    ({ ...acc, [key]: value }),
                {},
            );
        }

        case ADVANTAGE_PAGE_ENTITY:
        case REVIEWS_PAGE_ENTITY:
        case HOW_IT_WORKS_PAGE_ENTITY:
        case PHOTOMITRA_ADVANTAGE_PAGE_ENTITY:
        case PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY:
        case PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'iconImage') {
                    const url = new URL(value);
                    return { ...acc, [key]: url.pathname.substring(1) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

 case ABOUT_PAGE_OUR_TEAM:
        case RELATED_ARTICLE: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'url' && value != '') {
                    const url = new URL(value, testImageUrl);
                    //eslint-disable-next-line
                    console.log(url);
                    return { ...acc, [key]: url.pathname.substring(1) };
                }
                //eslint-disable-next-line
                console.log(rawPayload);
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_SERVICES: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'parameters') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (imageKeyList.includes(key) && !isCreateMode) {
                    const url = new URL(value);
                    return { ...acc, [key]: url.pathname.substring(1) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_PACKAGE_PLANS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'features') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'documents') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case PACKAGE_PROCESS_STEPS: {
            const { description, sequence, packages } = rawPayload;
            const payload = {
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    package: { id: packages },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case PACKAGE_TIMELINE: {
            const { packages, name, table } = rawPayload;
            const payload = {
                name,
                table: JSON.stringify(table),
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    package: { id: packages },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }
        case PACKAGE_PROCESS: {
            const { packages, sequence, heading, description, subtitle } = rawPayload;
            const payload = {
                sequence,
                heading,
                description,
                subtitle: Array.isArray(subtitle) ? subtitle?.map((sub: any) => ({ id: sub?.id })) : [],
            };
            if (isCreateMode) {
                return {
                    data: [
                        {
                            ...payload,
                            package: { id: packages },
                        },
                    ],
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

	   case CONTACT_INFO: {
      return Object.entries(rawPayload).reduce(
        (acc: any, [key, value]: any) => {
          if (
            [
              'facebook',
              'facebookGroup',
              'youtube',
              'instagram',
              'linkedIn',
              'x',
              'pinterest',
              'tiktok',
              'reddit',
              'whatsappChat',
              'whatsappGroup',
            ].includes(key)
          ) {
            // Assuming value is a string representing the social media link
            const socialMediaLinks = {
              ...acc.socialMediaLinks,
              [key]: `${rawPayload[key]}`,
            };
            return { ...acc, socialMediaLinks };
          }
          return acc;
        },
        { socialMediaLinks: {} }
      );
    }

        case CONDITIONS_PAGE: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'term') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case PHOTO_STORE_PLAN: {
            const transformedPayload: any = {
                type: rawPayload.title,
                price: rawPayload.pricePerMember,
                discountedPrice: rawPayload.discountedPricePerMember,
            };

            return {
                ...transformedPayload,
            };
        }

        case DEFECT_TYPES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'subcategory') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case SERVICE_PROCESS_DETAILS: {
            const { description, sequence, heading, service } = rawPayload;
            const payload = {
                heading: heading,
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case SERVICE_PROCESS_SUBTITLE: {
            const { description, sequence, heading, title } = rawPayload;
            const payload = {
                heading: heading,
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    title: { id: title },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case SERVICE_PROCESS_STEPS: {
            const { description, sequence, service } = rawPayload;
            const payload = {
                description: description,
                sequence: sequence,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }
        case SERVICE_TIMELINE: {
            const { service, name, table } = rawPayload;
            const payload = {
                name,
                table,
            };
            if (isCreateMode) {
                return {
                    ...payload,
                    service: { id: service },
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case APPLICATION_FORM_PACKAGES: {
            const {
                name,
                description,
                documitraFees,
                governmentFees,
                serviceAttributes,
                plans,
                planInfo,
                packageInfo,
                service,
                applyingFrom,
                destination,
                citizenship,
            } = rawPayload;
            const attributesIDs = serviceAttributes?.map((attribute: any) => attribute?.id);
            const payload = {
                name: name,
                description: description,
                governmentFees: !governmentFees?.length ? '' : governmentFees,
                documitraFees: documitraFees,
                serviceAttributes: attributesIDs,
                plans: plans,
                planInfo: planInfo,
                packageInfo: !packageInfo?.length ? '' : packageInfo,
                applyingFrom: applyingFrom,
                destination: destination,
                citizenship: citizenship,
                service: { id: service },
            };
            if (!isCreateMode) {
                return {
                    ...payload,
                };
            } else {
                return {
                    ...payload,
                };
            }
        }

        case FORMS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'package') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'steps') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case PHOTO_ORDER_APPLICATION: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case STEPS_QUESTIONS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'question') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case ASSETS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case LOCATIONS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case SUBCATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'category') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORIES_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'asset') {
                    return { ...acc, [key]: { id: value } };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case COMPLAINTS_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'defectType') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case TIME_LINE_HEADING:
        case SERVICE_BASED_FAQ: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'service') {
                    return { ...acc, [key]: { id: value } };
                }
                return { ...acc, [key]: value };
            }, {});
        }

        case TIME_LINE_BODY: {
            const { name, date, timeLine } = rawPayload;
            const formatDate = (date: any) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            // Convert date to local dates and format
            const formattedValidFrom = formatDate(new Date(date));

            const payload = {
                name: name,
                date: formattedValidFrom,
                timeLine: { id: timeLine },
            };
            return {
                ...payload,
            };
        }

        case MANAGER_TECHNICIAN_ENTITY: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'technicians') {
                    return { ...acc, [key]: value.map((id: string) => id) };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: { id: value } };
                }
            }, {});
        }
    }

    const entityNamesArray = entityName.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        // case PROCESSED_PHOTO_ENTITY: {
        //     return {
        //         processedPhoto: rawPayload.processedPhoto,
        //         processedPhotoGrid: rawPayload.processedPhotoGrid,
        //     };
        // }
        case PROCESSED_PHOTO_ENTITY: {
            const { processedPhoto, processedPhotoGrid } = rawPayload;

            return {
                processedPhoto: {
                    name: processedPhoto.name,
                    size: processedPhoto.size,
                    url: processedPhoto.url,
                },
                processedPhotoGrid: {
                    name: processedPhotoGrid.name,
                    size: processedPhotoGrid.size,
                    url: processedPhotoGrid.url,
                },
            };
        }

        default:
            return rawPayload;
    }
};
