export const ROLES_ENTITY = '/roles';
export const DEFECT_TYPES_ENTITY = '/defect-types';
export const COMPLAINT_STATUS = '/complaint-status';
export const USERS_ENTITY = '/admin/users';
export const FAQ_PAGE_ENTITY = '/documitra-content/faqs';
export const PHOTOMITRA_FAQ_PAGE_ENTITY = '/photomitra-content/faqs';
export const SERVICE_PAGE_ENTITY = '/documitra-content/services';
export const REVIEWS_PAGE_ENTITY = '/documitra-content/reviews';
export const ADVANTAGE_PAGE_ENTITY = '/documitra-content/advantages';
export const HOW_IT_WORKS_PAGE_ENTITY = '/documitra-content/how-it-works';
export const PHOTOMITRA_ADVANTAGE_PAGE_ENTITY = '/photomitra-content/advantages';
export const PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY = '/photomitra-content/how-it-works';
export const PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY = '/photomitra-content/taking-photo-steps';
export const PHOTO_STORE_PHOTO_SIZE = '/photo-store/photo-size';
export const PHOTO_STORE_FILE_TYPE = '/photo-store/file-type';
export const PHOTO_STORE_PLAN = '/photo-store/plans';
export const CONTACT_INFO = '/contact/contact-info';
export const CONTACT_FORM = '/contact/user-info';
export const APPLICATION_FORM_SERVICES = '/services';
export const APPLICATION_FORM_PARAMETERS = '/parameters';
export const APPLICATION_FORM_QUESTIONS = '/questions';
export const APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES = '/packages/service-attributes';
export const APPLICATION_FORM_PACKAGE_DOCUMENTS = '/packages/documents';
export const APPLICATION_FORM_PACKAGE_FEATURES = '/packages/features';
export const APPLICATION_FORM_PACKAGE_PLANS = '/packages/plans';
export const APPLICATION_FORM_PACKAGES = '/packages';
export const SERVICE_PROCESS_STEPS = '/service-process/process-steps';
export const PACKAGE_PROCESS_STEPS = '/package-process/process-steps';
export const SERVICE_PROCESS_DETAILS = '/service-process/process';
export const SERVICE_PROCESS_SUBTITLE = '/service-process/process/subtitle';
export const SERVICE_TIMELINE = '/timeline';
export const PACKAGE_TIMELINE = '/package-timeline';
export const PACKAGE_PROCESS = '/package-process/process';
export const PACKAGE_PROCESS_SUBTITLE = '/package-process/process/subtitle';
export const FORMS = '/forms';
export const FORMS_STEPS = '/forms/steps';
export const STEPS_QUESTIONS = '/forms/step-question';
export const PHOTO_ORDER_APPLICATION = '/photo-orders/application';
export const PHOTO_ORDER_TRANSACTION = '/photo-orders/transactions-photomitra';
export const DOCUMITRA_ORDER_APPLICATION = '/application/admin-order';
export const DOCUMITRA_ORDER_TRANSACTION = '/application/transactions-documitra';
export const COUPONS_MANAGEMENT = '/coupons-management';
export const OFFER_MANAGEMENT = '/offer-management';
export const BANNER_MANAGEMENT = '/banner-management';
export const PARTNER_SERVICE = '/partner-service';
export const BUCKET_MANAGEMENT = '/bucket-management';
export const TERMS_PAGE = '/terms-condition/terms';
export const CONDITIONS_PAGE = '/terms-condition/condition';
export const ABOUT_PAGE_OUR_TEAM = '/about';
export const SERVICE_BASED_FAQ = '/faq';
export const RELATED_ARTICLE = '/article';
export const TIME_LINE_HEADING = '/timeline';
export const TIME_LINE_BODY = '/timeline-body';
export const COUNTRY = '/admin/countries';
export const ORDER_USER = '/users';
export const DOCUMITRA_ORDER_PROCESSED_PDF = '/application/admin-order/documents';
export const DOCUMENT_ACCEPT_REJECT = '/application/admin-order/approve-document';
export const DOCUMITRA_LOGS = '/admin/logs';
export const DOCUMENT_DELETE_BY_MEMBER = '/application/processed-doc';
export const DOCUMENT_USER_ADDITIONAL_DETAILS = '/application/members';
export const REFFERAL_DETAILS = '/refer-earn/referred/users';

export const CATEGORIES_ENTITY = '/categories';
export const SUBCATEGORIES_ENTITY = '/subcategories';
export const LOCATIONS_ENTITY = '/locations';
export const COMPLAINTS_ENTITY = '/complaints';
export const VARIANTS_ENTITY = '/variants';
export const ORDER_LIST_ENTITY = '/orders';
export const AVAILABLE_DELIVERY_PARTNERS = '/delivery-partners/available';
export const MANAGER_TECHNICIAN_ENTITY = '/manager-technician';
export const ALL_TECHNICIAN_ENTITY = '/users/technicians';
export const ALL_MANAGERS_ENTITY = '/users/managers';
export const ASSETS_ENTITY = '/assets';
export const LOCATION_TYPES_ENTITY = '/location-types';

export const STORES_ENTITY = '/stores';
export const SLOTS_ENTITY = '/slots';
export const STORE_STATUS_ENTITY = '/store-status';
export const INVENTORIES_ENTITY = '/inventory';
export const STORE_INVENTORIES_ENTITY = '/store-inventory';
export const PROCESSED_PHOTO_ENTITY = '/items';
export const STORE_VARIANT_PRICES_ENTITY = '/store-variants';
export const STORE_DELIVERY_SLOTS_ENTITY = '/slots';
export const OPTION_TYPES_ENTITY = '/option-types';
export const OPTIONS_ENTITY = '/options';
export const PINCODES_ENTITY = '/pincodes';

export const UNASSIGN_DELIVERY_PARTNER_ENTITY = '/delivery-partners/unassigned';
export const ADDONS_ENTITY = '/addons';
export const STORE_ADDONS_ENTITY = '/store-addons';
export const BULK_ADDONS_ENTITY = '/bulk-addons';
export const STORE_BULK_ADDONS_ENTITY = '/store-bulk-addons';
export const SALES_REPORT = '/stores/download/sales';
export const STORE_DASHBOARD_ENTITY = '/dashboard';
export const STORE_ALL_SUBCATEGORY = '/stores/subcategories';
export const STORE_SUBCATEGORY_INVENTORIES_ENTITY = '/subcategory/variants';
export const SUBCATEGORY_INVENTORIES_ENTITY = '/subcategory/inventory';
export const CUSTOMER_PROFILE_ENTITY = '/customers';
export const PROCESSED_PHOTO_ITEMS_ENTITY = '/items';
export const COUNTRY_CODE = '/country-codes';
export const FORGOT_PASSWORD = '/admin/email/send';
export const RESET_PASSWORD = '/admin/reset-password';
export const CHANGE_PASSWORD = '/admin/verify-update-password';
