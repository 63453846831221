import { CUSTOMER_PROFILE_ENTITY, STORES_ENTITY, USERS_ENTITY } from './EntityName';

/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Users = '/admin/users',
    Faq = '/documitra-content/faqs',
    Advantage = '/documitra-content/advantages',
    HowItWorks = '/documitra-content/how-it-works',
    Reviews = '/documitra-content/reviews',
    PhotomitraAdvantage = '/photomitra/advantages',
    PhotomitraHowItWorks = '/photomitra/how-it-works',
    PhotomitraTakingPhotoStep = '/photomitra/taking-photo-steps',
    PhotomitraFaq = '/photomitra-content/faqs',
    PhotoStorePhotSize = '/photo-store/photo-size',
    PhotoStoreFileType = '/photo-store/file-type',
    PhotoStorePlan = '/photo-store/plans',
    ContactInfo = '/contact/contact-info',
    ContactForm = '/contact/user-info',
    ApplicationFormServices = '/services',
    ApplicationFormParameters = '/parameters',
    ApplicationFormQuestion = '/questions',
    ApplicationFormStepQuestion = '/form/step-question',
    ApplicationFormPackageServiceAttributes = '/packages/service-attributes',
    ApplicationFormPackageDocuments = '/packages/documents',
    ApplicationFormPackageFeatures = '/packages/features',
    ApplicationFormPackagePlans = '/packages/plans',
    ApplicationFormPackages = '/packages',
    ServiceProcessSteps = '/service-process/process-steps',
    PackageProcessSteps = '/package-process/process-steps',
    ServiceProcessDetailsProcess = '/service-process/process',
    ServiceProcessSubtitle = '/service-process/process/subtitle',
    ServiceTimeline = '/timeline',
    PackageTimeline = '/package-timeline',
    PackageProcess = '/package-process/process',
    ApplicationForm = '/forms',
    ApplicationFormSteps = '/forms/steps',
    PhotoOrderApplication = '/photo-orders/application',
    PhotoOrderTransaction = '/photo-orders/transaction',
    DocumitraOrderApplication = '/documitra-orders/application',
    DocumitraOrderTransaction = '/documitra-orders/transaction',
    CouponsManagement = '/coupons-management',
    OfferManagement = '/offer-management',
    BannerManagement = '/banner-management',
    PartnerService = '/partner-management',
    BucketManagement = '/bucket-management',
    TermsConditionTerms = '/terms-condition/terms',
    TermsConditionConditions = '/terms-condition/condition',
    AboutPageOurTeam = '/about',
    ServiceBasedFAQ = '/faq',
    RelatedArticle = '/article',
    TimeLineHeading = '/timeLine',
    TimeLineBody = '/timeLine-body',
    ForgotPassword = '/forgot-password',
    ResetPassword = '/reset-password',
    ChangePassword = '/change-password',
    SignedUpUsers = '/users',
    DocumitraLogs = '/admin/logs',
    RefferalDetails = '/refferal-details',

    // Remove After Final Work
    StoreStatus = '/store-status',
    Inventory = '/inventory',
    Categories = '/categories',
    Subcategories = '/subcategories',
    defectTypes = '/Defect-Types',
    complainStatus = '/complaint-status',
    locations = '/locations',
    complaintsPage = '/complaints',
    OrderList = '/orderList',
    managerTechnician = '/manager-technician',
    assets = '/assets',
    locationType = '/location-type',

    Stores = '/stores',
    OptionTypes = '/option-types',
    Options = '/options',
    Items = '/items',
    CollectionTypes = '/collection-types',
    Collections = '/collections',
    CollectionVariants = '/collection-buckets',
    Variants = '/variants',
    CustomerProfile = '/customers',
    DeliveryPartners = '/delivery-partners',
    Homepage = '/homepage',
    CategoryPage = '/category-page',
    RecipePage = '/recipe',
    CouponManagement = '/coupons',
    Constant = '/constant',
    RatingAndReview = '/download-reviews',
    RefundsPage = '/refunds',
    KitchenHomePage = '/kitchen-homepage',
    KitchenCategoryPage = '/kitchen-category-page',
    BlogsPage = '/blogs',
    AddOns = '/addons',
    BulkAddOns = '/bulk-addons',
    SalesReport = '/sales-report',
}

const entityArray = [
    {
        key: 'item-1',
        entityName: 'Users',
        entityUrl: AppRoutes.Users,
        children: [
            { key: 'Admin Users', entityName: 'Admin Users', entityUrl: AppRoutes.Users },
            { key: 'Documitra Users', entityName: 'Documitra Users', entityUrl: AppRoutes.SignedUpUsers },
        ],
    },
    {
        key: 'item-2',
        entityName: 'Home Page',
        entityUrl: AppRoutes.Faq,
        children: [
            { key: 'FAQ', entityName: 'Documitra FAQ', entityUrl: AppRoutes.Faq },
            { key: 'Why-Documitra', entityName: 'Why Documitra', entityUrl: AppRoutes.Advantage },
            { key: 'Reviews', entityName: 'Reviews', entityUrl: AppRoutes.Reviews },
            { key: 'How-It-Works', entityName: 'How It Works', entityUrl: AppRoutes.HowItWorks },
        ],
    },
    {
        key: 'item-3',
        entityName: 'Photomitra Page',
        entityUrl: AppRoutes.PhotomitraAdvantage,
        children: [
            {
                key: 'Photomitra-Why-Photomitra',
                entityName: 'Why Photomitra ?',
                entityUrl: AppRoutes.PhotomitraAdvantage,
            },
            { key: 'Photomitra-How-It-Works', entityName: 'How It Works', entityUrl: AppRoutes.PhotomitraHowItWorks },
            {
                key: 'Photomitra-Taking-Photo-Step',
                entityName: 'Taking Photo Step',
                entityUrl: AppRoutes.PhotomitraTakingPhotoStep,
            },
            { key: 'Photomitra FAQ', entityName: 'Photomitra FAQ', entityUrl: AppRoutes.PhotomitraFaq },
        ],
    },
    {
        key: 'item-4',
        entityName: 'Photo-Store',
        entityUrl: AppRoutes.PhotoStorePhotSize,
        children: [
            { key: 'Photo-Size', entityName: 'Photo Size', entityUrl: AppRoutes.PhotoStorePhotSize },
            { key: 'File-Type', entityName: 'File Type', entityUrl: AppRoutes.PhotoStoreFileType },
            { key: 'Plans', entityName: 'Plans Page', entityUrl: AppRoutes.PhotoStorePlan },
        ],
    },
    { key: 'item-5', entityName: 'Contact Info', entityUrl: AppRoutes.ContactInfo },
    { key: 'item-6', entityName: 'Contact Form', entityUrl: AppRoutes.ContactForm },
    { key: 'item-7', entityName: 'Services', entityUrl: AppRoutes.ApplicationFormServices },
    {
        key: 'item-8',
        entityName: 'Packages',
        entityUrl: AppRoutes.ApplicationFormPackageServiceAttributes,
        children: [
            {
                key: 'Package-Documents',
                entityName: 'Documents',
                entityUrl: AppRoutes.ApplicationFormPackageDocuments,
            },
            {
                key: 'Package-Features',
                entityName: 'Features',
                entityUrl: AppRoutes.ApplicationFormPackageFeatures,
            },
            {
                key: 'Packages',
                entityName: 'Packages',
                entityUrl: AppRoutes.ApplicationFormPackages,
            },
            { key: 'Question-crud', entityName: 'Questions', entityUrl: AppRoutes.ApplicationFormQuestion },
            {
                key: 'Form',
                entityName: 'Forms',
                entityUrl: AppRoutes.ApplicationForm,
            },
            { key: 'Package-process', entityName: 'Processes', entityUrl: AppRoutes.PackageProcess },
            { key: 'Package-steps', entityName: 'Steps', entityUrl: AppRoutes.PackageProcessSteps },
            {
                key: 'Package-timeline',
                entityName: 'Timeline',
                entityUrl: AppRoutes.PackageTimeline,
            },
        ],
    },
    {
        key: 'item-11',
        entityName: 'Photo Order',
        entityUrl: AppRoutes.PhotoOrderApplication,
    },
    {
        key: 'item-12',
        entityName: 'Documitra Order',
        entityUrl: AppRoutes.DocumitraOrderApplication,
    },
    {
        key: 'transactions',
        entityName: 'Transactions',
        entityUrl: AppRoutes.DocumitraOrderTransaction,
        children: [
            { key: 'photomitra_Transactions', entityName: 'Photomitra', entityUrl: AppRoutes.PhotoOrderTransaction },
            { key: 'documitra_Transactions', entityName: 'Documitra', entityUrl: AppRoutes.DocumitraOrderTransaction },
        ],
    },
    { key: '13', entityName: 'Coupon Management', entityUrl: AppRoutes.CouponsManagement },
    { key: '14', entityName: 'Offer Management', entityUrl: AppRoutes.OfferManagement },
    // { key: '15', entityName: 'Partner Services', entityUrl: AppRoutes.PartnerService },
    { key: '16', entityName: 'Banner Management', entityUrl: AppRoutes.BannerManagement },
    // { key: '17', entityName: 'Bucket Management', entityUrl: AppRoutes.BucketManagement },
    { key: '18', entityName: 'User Refferals', entityUrl: AppRoutes.RefferalDetails },
    {
        key: 'item-17',
        entityName: 'External Pages',
        entityUrl: AppRoutes.TermsConditionTerms,
        children: [
            { key: 'Terms-Condition-terms', entityName: 'Terms Page', entityUrl: AppRoutes.TermsConditionTerms },
            {
                key: 'Terms-Condition-conditions',
                entityName: 'Conditions Page',
                entityUrl: AppRoutes.TermsConditionConditions,
            },
            {
                key: 'About-page-our-team',
                entityName: 'About Us Team',
                entityUrl: AppRoutes.AboutPageOurTeam,
            },
            {
                key: 'Service-Based-FAQ',
                entityName: 'Service FAQ',
                entityUrl: AppRoutes.ServiceBasedFAQ,
            },
            {
                key: 'Related-Article',
                entityName: 'Related Article',
                entityUrl: AppRoutes.RelatedArticle,
            },
        ],
    },
    { key: '18', entityName: 'Logs & History', entityUrl: AppRoutes.DocumitraLogs },
];

type StatusType = { id: string; value: string; name: string }[];

export const statusOption: StatusType = [
    { id: '1', value: 'Expert review and editing in progress', name: 'Expert review and editing in progress' },
    { id: '2', value: 'Editing Failed', name: 'Editing Failed' },
    { id: '3', value: 'Printing in progress', name: 'Printing in progress' },
    { id: '4', value: 'Print order send to studio', name: 'Print order send to studio' },
    { id: '5', value: 'Completed', name: 'Completed' },
    { id: '6', value: 'initialized', name: 'Initialized' },
    { id: '7', value: 'Processed photo added', name: 'Processed photo added' },
    { id: '8', value: 'Order dispatched', name: 'Order dispatched' },
];

export const documitraOrderStatusOption: StatusType = [
    { id: '1', value: 'Additional information required', name: 'Additional information required' },
    { id: '2', value: 'Government fee payment pending', name: 'Government fee payment pending' },
    { id: '3', value: 'Biometrics appointment booked', name: 'Biometrics appointment booked' },
    { id: '4', value: 'Government fee paid by user', name: 'Government fee paid by user' },
    { id: '5', value: 'Documents received and under review', name: 'Documents received and under review' },
    { id: '6', value: 'Remarks have been added', name: 'Remarks have been added' },
    { id: '7', value: 'Notary pending', name: 'Notary pending' },
    { id: '8', value: 'Package in transit from documitra', name: 'Package in transit from documitra' },
    { id: '9', value: 'Application ready for submission', name: 'Application ready for submission' },
    { id: '10', value: 'Final submit', name: 'Final submit' },
    { id: '11', value: 'Application Closed', name: 'Application Closed' },
    { id: '12', value: 'Form Filling under process', name: 'Form Filling under process' },
    { id: '13', value: 'Form Review Pending by applicant', name: 'Form Review Pending by applicant' },
    { id: '14', value: 'Remarks added by the user', name: 'Remarks added by the user' },
    { id: '15', value: 'Form accepted by the user', name: 'Form accepted by the user' },
    { id: '16', value: 'Refund issued', name: 'Refund issued' },
];
export const documitraVucketOption: StatusType = [
    { id: '1', value: 'Passport', name: 'Additional information required' },
    { id: '2', value: 'Government fee payment pending', name: 'Government fee payment pending' },
    { id: '3', value: 'Biometrics appointment booked', name: 'Biometrics appointment booked' },
    { id: '4', value: 'Government fee paid by user', name: 'Government fee paid by user' },
    { id: '5', value: 'Documents received and under review', name: 'Documents received and under review' },
    { id: '6', value: 'Remarks have been added', name: 'Remarks have been added' },
];

export { entityArray };

export const routesEntityArray = [USERS_ENTITY, CUSTOMER_PROFILE_ENTITY, STORES_ENTITY];

export const STORE_NESTED_URL_MAPPING = {
    STORE_DASHBAORD: 'dashboard',
    STORE_INVENTORY: 'store-inventory',
    PROCESSED_PHOTO: 'items',
    STORE_VARAINT: 'store-variant',
    STORE_ADD_ONS: 'store-add-ons',
    STORE_BULK_ADD_ONS: 'store-bulk-add-ons',
    STORE_SLOTS: 'slots',
};

export const storeNestedUrlArray = [
    STORE_NESTED_URL_MAPPING.STORE_DASHBAORD,
    STORE_NESTED_URL_MAPPING.STORE_INVENTORY,
    STORE_NESTED_URL_MAPPING.PROCESSED_PHOTO,
    STORE_NESTED_URL_MAPPING.STORE_VARAINT,
    STORE_NESTED_URL_MAPPING.STORE_ADD_ONS,
    STORE_NESTED_URL_MAPPING.STORE_BULK_ADD_ONS,
    STORE_NESTED_URL_MAPPING.STORE_SLOTS,
];

export const inputTypesOptions: any = [
    {
        id: '1',
        value: 'text',
        name: 'Text',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Text length',
                value: '',
                placeholder: 'Enter required length',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min text length',
                value: '',
                placeholder: 'Enter minimum required length',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max text length',
                value: '',
                placeholder: 'Enter maximum required length',
                errorMessage: '',
            },
            {
                type: 'matches',
                name: 'Matches pattern',
                value: '',
                placeholder: 'Enter regex pattern',
                errorMessage: '',
            },
            { type: 'url', name: 'Ensure URL', errorMessage: '' },
            { type: 'uuid', name: 'Ensure UUID', errorMessage: '' },
            { type: 'trim', name: 'Ensure Trimmed', errorMessage: '' },
            { type: 'lowercase', name: 'Ensure Lowercase', errorMessage: '' },
            { type: 'uppercase', name: 'Ensure Uppercase', errorMessage: '' },
        ],
    },
    {
        id: '2',
        value: 'number',
        name: 'Number',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'min',
                name: 'Minimum Number',
                value: '',
                placeholder: 'Enter required minimum number',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Maximum Number',
                value: '',
                placeholder: 'Enter required maximum number',
                errorMessage: '',
            },
            {
                type: 'lessThan',
                name: 'Less Than Number',
                value: '',
                placeholder: 'Enter required less than number',
                errorMessage: '',
            },
            {
                type: 'moreThan',
                name: 'More Than Number',
                value: '',
                placeholder: 'Enter required more than number',
                errorMessage: '',
            },
            { type: 'positive', name: 'Ensure Positive', errorMessage: '' },
            { type: 'negative', name: 'Ensure Negative', errorMessage: '' },
        ],
    },
    {
        id: '3',
        value: 'date',
        name: 'Date',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'min',
                name: 'Minimum Date',
                value: '',
                placeholder: 'Enter required minimum date',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Maximum Date',
                value: '',
                placeholder: 'Enter required maximum date',
                errorMessage: '',
            },
        ],
    },
    {
        id: '4',
        value: 'time',
        name: 'Time',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'min',
                name: 'Minimum time',
                value: '',
                placeholder: 'Enter required minimum time',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Maximum time',
                value: '',
                placeholder: 'Enter required maximum time',
                errorMessage: '',
            },
        ],
    },
    {
        id: '5',
        value: 'short answer',
        name: 'Short answer',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Text length',
                value: '',
                placeholder: 'Enter required length',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min text length',
                value: '',
                placeholder: 'Enter minimum required length',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max text length',
                value: '',
                placeholder: 'Enter maximum required length',
                errorMessage: '',
            },
            {
                type: 'matches',
                name: 'Matches pattern',
                value: '',
                placeholder: 'Enter regex pattern',
                errorMessage: '',
            },
            { type: 'url', name: 'Ensure URL', errorMessage: '' },
            { type: 'uuid', name: 'Ensure UUID', errorMessage: '' },
            { type: 'trim', name: 'Ensure Trimmed', errorMessage: '' },
            { type: 'lowercase', name: 'Ensure Lowercase', errorMessage: '' },
            { type: 'uppercase', name: 'Ensure Uppercase', errorMessage: '' },
        ],
    },
    {
        id: '6',
        value: 'paragraph',
        name: 'Paragraph',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Text length',
                value: '',
                placeholder: 'Enter required length',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min text length',
                value: '',
                placeholder: 'Enter minimum required length',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max text length',
                value: '',
                placeholder: 'Enter maximum required length',
                errorMessage: '',
            },
            {
                type: 'matches',
                name: 'Matches pattern',
                value: '',
                placeholder: 'Enter regex pattern',
                errorMessage: '',
            },
            { type: 'url', name: 'Ensure URL', errorMessage: '' },
            { type: 'uuid', name: 'Ensure UUID', errorMessage: '' },
            { type: 'trim', name: 'Ensure Trimmed', errorMessage: '' },
            { type: 'lowercase', name: 'Ensure Lowercase', errorMessage: '' },
            { type: 'uppercase', name: 'Ensure Uppercase', errorMessage: '' },
        ],
    },
    {
        id: '7',
        value: 'multiple choice',
        name: 'Multiple choice',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Number of selections',
                value: '',
                placeholder: 'Enter required no. of selections',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min. Number of selections',
                value: '',
                placeholder: 'Enter required minimum no. of selections',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max. Number of selections',
                value: '',
                placeholder: 'Enter required maximum no. of selections',
                errorMessage: '',
            },
        ],
    },
    {
        id: '8',
        value: 'checkboxes',
        name: 'Checkboxes',
        validations: [{ type: 'enforceChecked', name: 'Enforce checked', errorMessage: '' }],
    },
    {
        id: '9',
        value: 'dropdown',
        name: 'Dropdown',
        validations: [{ type: 'required', name: 'Required', errorMessage: '' }],
    },
    {
        id: '10',
        value: 'file upload',
        name: 'File upload',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'maxSize',
                name: 'Max file size',
                value: '',
                placeholder: 'Enter max file size in MB. e.g. 10',
                errorMessage: '',
            },
            {
                type: 'fileTypes',
                name: 'File types',
                value: '',
                placeholder: 'Enter accepted file types with comma. e.g. pdf,docx,jpg',
                errorMessage: '',
            },
        ],
    },
    {
        id: '11',
        value: 'currency',
        name: 'Currency',
        validations: [{ type: 'required', name: 'Required', errorMessage: '' }],
    },
    {
        id: '12',
        value: 'email',
        name: 'Email',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            { type: 'email', name: 'Ensure Email', errorMessage: '' },
        ],
    },
    {
        id: '13',
        value: 'phone',
        name: 'Phone',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Phone No. length',
                value: '',
                placeholder: 'Enter required phone no. length',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min. Phone No. length',
                value: '',
                placeholder: 'Enter required minimum phone no. length',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max. Phone No. length',
                value: '',
                placeholder: 'Enter required maximum phone no. length',
                errorMessage: '',
            },
            {
                type: 'matches',
                name: 'Matches pattern',
                value: '',
                placeholder: 'Enter regex pattern',
                errorMessage: '',
            },
        ],
    },
    {
        id: '14',
        value: 'image upload',
        name: 'Image upload',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'maxSize',
                name: 'Max file size',
                value: '',
                placeholder: 'Enter max file size in MB. e.g. 10',
                errorMessage: '',
            },
            {
                type: 'fileTypes',
                name: 'File types',
                value: '',
                placeholder: 'Enter accepted file types with comma. e.g. jpg,png,jpeg',
                errorMessage: '',
            },
        ],
    },
    {
        id: '15',
        value: 'description',
        name: 'Description',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Text length',
                value: '',
                placeholder: 'Enter required length',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min text length',
                value: '',
                placeholder: 'Enter minimum required length',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max text length',
                value: '',
                placeholder: 'Enter maximum required length',
                errorMessage: '',
            },
            {
                type: 'matches',
                name: 'Matches pattern',
                value: '',
                placeholder: 'Enter regex pattern',
                errorMessage: '',
            },
            { type: 'url', name: 'Ensure URL', errorMessage: '' },
            { type: 'uuid', name: 'Ensure UUID', errorMessage: '' },
            { type: 'trim', name: 'Ensure Trimmed', errorMessage: '' },
            { type: 'lowercase', name: 'Ensure Lowercase', errorMessage: '' },
            { type: 'uppercase', name: 'Ensure Uppercase', errorMessage: '' },
        ],
    },
    {
        id: '16',
        value: 'rating',
        name: 'Rating',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'min',
                name: 'Min Rating',
                value: '',
                placeholder: 'Enter minimum required rating',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max Rating',
                value: '',
                placeholder: 'Enter maximum required rating',
                errorMessage: '',
            },
        ],
    },
    {
        id: '17',
        value: 'unique ID',
        name: 'Unique ID',
        validations: [
            { type: 'required', name: 'Required', errorMessage: '' },
            {
                type: 'length',
                name: 'Text length',
                value: '',
                placeholder: 'Enter required length',
                errorMessage: '',
            },
            {
                type: 'min',
                name: 'Min text length',
                value: '',
                placeholder: 'Enter minimum required length',
                errorMessage: '',
            },
            {
                type: 'max',
                name: 'Max text length',
                value: '',
                placeholder: 'Enter maximum required length',
                errorMessage: '',
            },
            {
                type: 'matches',
                name: 'Matches pattern',
                value: '',
                placeholder: 'Enter regex pattern',
                errorMessage: '',
            },
            { type: 'url', name: 'Ensure URL', errorMessage: '' },
            { type: 'uuid', name: 'Ensure UUID', errorMessage: '' },
            { type: 'trim', name: 'Ensure Trimmed', errorMessage: '' },
            { type: 'lowercase', name: 'Ensure Lowercase', errorMessage: '' },
            { type: 'uppercase', name: 'Ensure Uppercase', errorMessage: '' },
        ],
    },
    {
        id: '18',
        value: 'radio',
        name: 'Radio',
        validations: [{ type: 'required', name: 'Required', errorMessage: '' }],
    },
    {
        id: '19',
        value: 'country dropdown',
        name: 'Country Dropdown',
        validations: [{ type: 'required', name: 'Required', errorMessage: '' }],
    },
    {
        id: '20',
        value: 'occupation dropdown',
        name: 'Occupation Dropdown',
        validations: [{ type: 'required', name: 'Required', errorMessage: '' }],
    },
    {
        id: '21',
        value: 'dependent',
        name: 'Dependent',
        validations: [{ type: 'required', name: 'Required', errorMessage: '' }],
    },
];

export enum Role {
    SUPER_ADMIN = 'Super Admin',
    ADMIN_OPS = 'Admin Ops',
    ADMIN_AGENT = 'Admin Agent',
    ACCOUNTANT = 'Accountant',
}

export const RemoveRolePermission: any = {
    [Role.SUPER_ADMIN]: {
        create: false,
        read: false,
        delete: false,
        update: false,
        disableModule: [],
    },
    [Role.ADMIN_OPS]: {
        create: false,
        read: false,
        delete: true,
        update: false,
        disableModule: [],
    },
    [Role.ADMIN_AGENT]: {
        create: true,
        read: false,
        delete: true,
        update: true,
        disableModule: [
            'Home Page',
            'Photomitra Page',
            'Users',
            'Coupon Management',
            'Packages',
            'Services',
            'Photo-Store',
            'Contact Info',
            'Logs & History',
            'User Refferals',
            'Banner Management',
            'Bucket Management',
            'Offer Management',
        ],
    },
    [Role.ACCOUNTANT]: {
        create: true,
        read: true,
        delete: true,
        update: true,
        disableModule: [
            'External Pages',
            'Coupon Management',
            'Packages',
            'Services',
            'Contact Form',
            'Contact Info',
            'Photo-Store',
            'Photomitra Page',
            'Home Page',
            'Users',
            'Logs & History',
            'User Refferals',
            'Banner Management',
            'Bucket Management',
            'Offer Management',
        ],
    },
};

export const reportType: any = {
    '/contact/user-info': 'UserInfo',
    '/application/admin-order': 'DocumitraOrder',
    '/photo-orders/application': 'PhotomitraOrder',
    '/users': 'Users',
    '/photo-orders/transactions-photomitra': 'PhotomitraTransaction',
    '/application/transactions-documitra': 'DocumitraTransaction',
};

export const TRANSACTION_DATA_FILTERS = [
    {
        key: 1,
        name: 'Date',
        componentType: 'DATE',
        level: 'date',
    },
    {
        key: 2,
        name: 'Payment Status',
        componentType: 'SELECT',
        level: 'paymentStatus',
        options: [
            { id: '1', value: 'Payment done', name: 'Payment done' },
            { id: '2', value: 'Payment pending', name: 'Payment pending' },
        ],
    },
];

export const LOGS_FILTER_DATA_MODEL = [
    {
        key: 1,
        name: 'Date',
        componentType: 'DATE',
        level: 'date',
    },
    {
        key: 2,
        name: 'Module Name',
        componentType: 'SELECT',
        level: 'moduleName',
        options: [
            { id: '1', name: 'Admin User', value: 'Admin User' },
            { id: '2', name: 'Documitra User', value: 'Documitra User' },
            { id: '3', name: 'Docunitra Faq', value: 'Docunitra Faq' },
            { id: '4', name: 'Why Documitra', value: 'Why Documitra' },
            { id: '5', name: 'Documitra Reviews', value: 'Documitra Reviews' },
            { id: '6', name: 'How It Works - Documitra', value: 'How It Works - Documitra' },
            { id: '7', name: 'Why Photomitra', value: 'Why Photomitra' },
            { id: '8', name: 'How It Works - Photomitra', value: 'How It Works - Photomitra' },
            { id: '9', name: 'Taking Photo Steps', value: 'Taking Photo Steps' },
            { id: '10', name: 'Photomitra Faq', value: 'Photomitra Faq' },
            { id: '11', name: 'Photo Size', value: 'Photo Size' },
            { id: '12', name: 'File Type', value: 'File Type' },
            { id: '13', name: 'Plans Page', value: 'Plans Page' },
            { id: '14', name: 'Contact Information', value: 'Contact Information' },
            { id: '15', name: 'Contact Form', value: 'Contact Form' },
            { id: '16', name: 'Services', value: 'Services' },
            { id: '17', name: 'Documents', value: 'Documents' },
            { id: '18', name: 'Features', value: 'Features' },
            { id: '19', name: 'Packages', value: 'Packages' },
            { id: '20', name: 'Questions', value: 'Questions' },
            { id: '21', name: 'Forms', value: 'Forms' },
            { id: '22', name: 'Processes', value: 'Processes' },
            { id: '23', name: 'Steps', value: 'Steps' },
            { id: '24', name: 'Timeline', value: 'Timeline' },
            { id: '25', name: 'Photo Order', value: 'Photo Order' },
            { id: '26', name: 'Documitra Order', value: 'Documitra Order' },
            { id: '27', name: 'Coupon Management', value: 'Coupon Management' },
            { id: '28', name: 'Terms Page', value: 'Terms Page' },
            { id: '29', name: 'Conditions Page', value: 'Conditions Page' },
            { id: '30', name: 'About Us Team', value: 'About Us Team' },
            { id: '31', name: 'Service Faq', value: 'Service Faq' },
            { id: '32', name: 'Related Article', value: 'Related Article' },
        ],
    },
    {
        key: 3,
        name: 'Action Type',
        componentType: 'SELECT',
        level: 'actionType',
        options: [
            { id: '1', value: 'created', name: 'Created' },
            { id: '2', value: 'updated', name: 'Updated' },
            { id: '3', value: 'deleted', name: 'Deleted' },
        ],
    },
];

export const DOCUMITRA_ORDER_DATA_MODEL = [
    {
        key: 1,
        name: 'DOB',
        componentType: 'DOB',
        level: 'dateOfBirth',
    },
    {
        key: 2,
        name: 'Reference Number',
        componentType: 'INPUT',
        level: 'referenceNumber',
    },
    {
        key: 3,
        name: 'Passport Number',
        componentType: 'INPUT',
        level: 'passportNumber',
    },
    {
        key: 4,
        name: 'Tracking Link',
        componentType: 'INPUT',
        level: 'trackingLink',
    },
];
