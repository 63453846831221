import { errorMsg } from '../constants/messages';
import { VerifiedOption } from './Stores';
const SignedUpUsersFormData = [
  {
    title: 'Signed-up User Details',
    name: 'id',
    label: 'User ID',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: {
      title: 'User ID',
      enableSort: false,
      allowAddRecordClick: true,
      columnType: 'text',
      render: (record: any) => record.id || record._id || 'N/A',
    },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'name',
    label: 'User Name',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: {
      title: 'User Name',
      enableSort: false,
      allowAddRecordClick: true,
    },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'phone',
    label: 'Phone',
    fieldType: {
      name: 'textarea',
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: { title: 'Phone' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'email',
    label: 'Email',
    fieldType: {
      name: 'textarea',
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: { title: 'Email' },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'createdAt',
    label: 'Registration Date',
    fieldType: {
      name: 'text',
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: {
      title: 'Registration Date',
      enableSort: true,
      columnType: 'registrationDate',
      render: (record: any) => {
        if (!record.createdAt) return '';
        return record.createdAt;
      },
    },
    isTableHeader: true,
    isAccordian: true,
    default: '',
  },
  {
    name: 'active',
    label: 'Active',
    fieldType: {
      name: 'select',
      options: [
        { name: 'True', value: true },
        { name: 'False', value: false },
      ],
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: {
      title: 'active',
      columnType: 'checkbox',
      allowAddRecordClick: false,
      render: (record: any) => ({
        value: record.active === true || record.active === 'true',
        record: record,
      }),
    },
    isTableHeader: true,
    isAccordian: true,
    default: true,
  },
  {
    name: 'isVerified',
    label: 'Verified',
    fieldType: {
      name: 'select',
      options: VerifiedOption,
    },
    required: true,
    errorMsg: errorMsg,
    tableMeta: {
      title: 'isVerified',
      columnType: 'isVerified',
    },
    isTableHeader: true,
    isAccordian: true,
    default: true,
  },
];

export { SignedUpUsersFormData };
