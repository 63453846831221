/* eslint-disable no-unused-vars */
import moment from 'moment';

import {
    USERS_ENTITY,
    SUBCATEGORIES_ENTITY,
    CATEGORIES_ENTITY,
    DEFECT_TYPES_ENTITY,
    ORDER_LIST_ENTITY,
    COMPLAINTS_ENTITY,
    MANAGER_TECHNICIAN_ENTITY,
    ASSETS_ENTITY,
    LOCATIONS_ENTITY,
    CONTACT_INFO,
    APPLICATION_FORM_QUESTIONS,
    APPLICATION_FORM_PACKAGE_PLANS,
    APPLICATION_FORM_SERVICES,
    APPLICATION_FORM_PACKAGES,
    SERVICE_PROCESS_STEPS,
    SERVICE_PROCESS_DETAILS,
    SERVICE_PROCESS_SUBTITLE,
    FORMS,
    PHOTO_ORDER_APPLICATION,
    STEPS_QUESTIONS,
    PROCESSED_PHOTO_ENTITY,
    COUPONS_MANAGEMENT,
    CONDITIONS_PAGE,
    SERVICE_BASED_FAQ,
    PHOTO_STORE_PLAN,
    TIME_LINE_HEADING,
    TIME_LINE_BODY,
    SERVICE_TIMELINE,
    PACKAGE_TIMELINE,
    PACKAGE_PROCESS_STEPS,
    PACKAGE_PROCESS,
    REFFERAL_DETAILS,
    ADVANTAGE_PAGE_ENTITY,
    REVIEWS_PAGE_ENTITY,
    HOW_IT_WORKS_PAGE_ENTITY,
    PHOTOMITRA_ADVANTAGE_PAGE_ENTITY,
    PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY,
    PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY,
    ABOUT_PAGE_OUR_TEAM,
    RELATED_ARTICLE,
} from '../constants/EntityName';
import { BASE_IMAGE_URL } from '../env';

export const positionSort = (firstEntity: { position: number }, secondEntity: { position: number }) =>
    firstEntity.position - secondEntity.position;

const imageKeyList = ['activeIcon', 'iconImage', 'inactiveIcon', 'serviceImage'];

export const getIncomingPayload = (entityName: string, apiData: any) => {
    let countryCode = '';
    let phoneNumber = '';
    function splitPhoneNumber(phoneNumber: any) {
        const lastIndex = phoneNumber.lastIndexOf('-');

        if (lastIndex !== -1) {
            const countryCode = phoneNumber.substring(0, lastIndex);
            const remainingDigits = phoneNumber.substring(lastIndex + 1);

            return [countryCode, remainingDigits];
        } else {
            return null;
        }
    }

    if (entityName === USERS_ENTITY) {
        const splitCode = splitPhoneNumber(apiData?.phone);
        countryCode = splitCode && splitCode[0];
        phoneNumber = splitCode && splitCode[1];
    }

    switch (entityName) {
        case USERS_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'phone') {
                    return { ...acc, [key]: phoneNumber };
                }

                if (key === 'createdAt') {
                    return { ...acc, [key]: countryCode };
                }

                if (key === 'password') {
                    return { ...acc, [key]: '*****' };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_QUESTIONS: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'service') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case DEFECT_TYPES_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'subcategory') {
                    return { ...acc, [key]: value.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case ADVANTAGE_PAGE_ENTITY:
        case REVIEWS_PAGE_ENTITY:
        case HOW_IT_WORKS_PAGE_ENTITY:
        case PHOTOMITRA_ADVANTAGE_PAGE_ENTITY:
        case PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY:
        case PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'iconImage') {
                    return {
                        ...acc,
                        [key]: value?.startsWith('https') || !value ? value : `${BASE_IMAGE_URL}/${value}`,
                    };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_SERVICES: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (imageKeyList.includes(key)) {
                    return {
                        ...acc,
                        [key]: value?.startsWith('https') || !value ? value : `${BASE_IMAGE_URL}/${value}`,
                    };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case ABOUT_PAGE_OUR_TEAM:
        case RELATED_ARTICLE: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'url') {
                    return {
                        ...acc,
                        [key]: value?.startsWith('https') || !value ? value : `${BASE_IMAGE_URL}/${value}`,
                    };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case LOCATIONS_ENTITY:
        case ASSETS_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'locationType') {
                    return { ...acc, [key]: value.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case CATEGORIES_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'asset') {
                    return { ...acc, [key]: value?.id };
                }
                if (key === 'manager') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case SUBCATEGORIES_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'category') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case COMPLAINTS_ENTITY: {
            return Object.entries(apiData).reduce((acc, [key, value]: any) => {
                if (key === 'defectType') {
                    return { ...acc, [key]: value?.id };
                }

                return { ...acc, [key]: value };
            }, {});
        }

        case APPLICATION_FORM_PACKAGES: {
            return {
                ...apiData,
                service: apiData?.service?.id,
                plans: apiData?.plans?.map((plans: { id: string }) => plans.id),
                serviceAttributes: apiData?.serviceAttributes?.map(
                    (serviceAttributes: { id: string }) => serviceAttributes.id,
                ),
                planInfo: apiData?.planInfo,
                packageInfo: apiData?.packageInfo,
                documitraFees: apiData?.documitraFees,
                governmentFees: apiData?.governmentFees,
            };
        }

        case STEPS_QUESTIONS: {
            return {
                ...apiData,
                question: `${apiData?.question?.id}`,
            };
        }

        case APPLICATION_FORM_PACKAGE_PLANS: {
            return {
                ...apiData,
                service: apiData?.service?.id,
                features: apiData?.features?.map((feature: { id: string }) => feature.id),
                documents: apiData?.documents?.map((document: { id: string }) => document.id),
                price: apiData?.price ? Number(apiData.price) : undefined,
            };
        }

        case PHOTO_STORE_PLAN: {
            return {
                title: apiData.title,
                pricePerMember: apiData?.pricePerMember ? Number(apiData.pricePerMember) : undefined,
                discountedPricePerMember: apiData?.discountedPricePerMember
                    ? Number(apiData.discountedPricePerMember)
                    : undefined,
            };
        }

        case FORMS: {
            const updatedApiData = {
                formName: apiData?.formName,
                steps: apiData?.steps?.map((step: any) => ({
                    sequence: step?.sequence,
                    id: step?.id,
                    stepTitle: step?.stepTitle,
                    stepTime: step?.stepTime,
                    stepQuestions: step?.stepQuestions?.map((stepQuestion: { id: string }) => stepQuestion),
                    // stepQuestions: step?.stepQuestions?.map(({ id, questionText, ...rest }: any) => ({
                    //     ...rest,
                    //     value: id,
                    //     id: id,
                    //     label: questionText,
                    //     questionText: questionText,
                    // })),
                })),
            };
            return {
                ...updatedApiData,
                package: apiData?.package?.id,
            };
        }

        case PACKAGE_PROCESS_STEPS: {
            return {
                ...apiData,
                packages: apiData?.package?.name,
            };
        }

        case PACKAGE_TIMELINE: {
            return {
                ...apiData,
                table: JSON.parse(apiData?.table),
                packages: apiData?.package?.name,
            };
        }
        case PACKAGE_PROCESS: {
            return {
                ...apiData,
                packages: apiData?.package?.id,
            };
        }

        case SERVICE_PROCESS_DETAILS:
        case SERVICE_PROCESS_STEPS: {
            return {
                ...apiData,
                service: apiData?.service?.id,
            };
        }
        case SERVICE_TIMELINE: {
            return {
                ...apiData,
                table: JSON.parse(apiData?.table),
                service: apiData?.service?.id,
            };
        }

        case SERVICE_PROCESS_SUBTITLE: {
            return {
                ...apiData,
                title: apiData?.title?.id,
            };
        }

        case CONDITIONS_PAGE: {
            return {
                ...apiData,
                term: apiData?.term?.id,
            };
        }

        case TIME_LINE_HEADING:
        case SERVICE_BASED_FAQ: {
            return {
                ...apiData,
                service: apiData?.service?.id,
            };
        }

        case TIME_LINE_BODY: {
            return {
                ...apiData,
                timeLine: apiData?.timeLine?.id,
                date: apiData.date ? moment(apiData.date, 'YYYY-MM-DD') : '',
            };
        }

        case MANAGER_TECHNICIAN_ENTITY: {
            return {
                manager: apiData?.results?.id,
                technicians: apiData?.results?.technicians?.map((technicians: { id: string }) => technicians.id),
            };
        }

        case PHOTO_ORDER_APPLICATION: {
            // Create a function to organize photos by ID
            const organizePhotosById = (items: any) => {
                const organizedPhotos: any = {};

                items?.forEach((item: any) => {
                    const { id, photo1, photo2, photo3, photo4 } = item;

                    // Create an array for each unique ID
                    if (!organizedPhotos[id]) {
                        organizedPhotos[id] = [];
                    }

                    // Push photo URLs to the corresponding ID array
                    organizedPhotos[id].push(photo1?.url, photo2?.url, photo3?.url, photo4?.url);
                });

                return organizedPhotos;
            };

            // Organize photos by ID
            // eslint-disable-next-line no-unused-vars
            const organizedPhotos = organizePhotosById(apiData?.items);

            return {
                ...apiData,
                userName: apiData?.user?.name,
                userEmail: apiData?.user?.email,
            };
        }

	    case CONTACT_INFO: {
      return {
        ...apiData,
        facebook: apiData?.socialMediaLinks?.facebook,
        facebookGroup: apiData?.socialMediaLinks?.facebookGroup,
        youtube: apiData?.socialMediaLinks?.youtube,
        instagram: apiData?.socialMediaLinks?.instagram,
        linkedIn: apiData?.socialMediaLinks?.linkedIn,
        whatsappChat: apiData?.socialMediaLinks?.whatsappChat,
        whatsappGroup: apiData?.socialMediaLinks?.whatsappGroup,
        x: apiData?.socialMediaLinks?.x,
        pinterest: apiData?.socialMediaLinks?.pinterest,
        tiktok: apiData?.socialMediaLinks?.tiktok,
        reddit: apiData?.socialMediaLinks?.reddit,
        email: apiData?.email,
      };
    }

		

        case REFFERAL_DETAILS: {
            return {
                ...apiData?.results,
                totalRefferals:
                    apiData?.results?.refferalCompleted?.length + apiData?.results?.refferalIncompleted?.length,
                refferalCompleted: apiData?.results?.refferalCompleted,
                refferalIncompleted: apiData?.results?.refferalIncompleted,
            };
        }

        case ORDER_LIST_ENTITY: {
            return Object.keys(apiData).reduce((acc, key) => {
                if (key === 'store') {
                    return {
                        ...acc,
                        store: apiData[key]?.name,
                        storePhone: apiData[key]?.phone,
                        line1: apiData[key]?.line1,
                        line2: apiData[key]?.line2,
                        state: apiData[key]?.state,
                        city: apiData[key]?.city,
                        latitude: apiData[key]?.latitude,
                        longitude: apiData[key]?.longitude,
                        landmark: apiData[key]?.landmark,
                        storePincode: apiData[key]?.storePincode,
                    };
                }

                if (key === 'address') {
                    return {
                        ...acc,
                        addressType: apiData[key]?.addressType,
                        customerLine1: apiData[key]?.line1,
                        customerLine2: apiData[key]?.line2,
                        streetName: apiData[key]?.streetName,
                        customerLatitude: apiData[key]?.latitude,
                        customerLongitude: apiData[key]?.longitude,
                        customerLandmark: apiData[key]?.landmark,
                        pincode: apiData[key]?.pincode,
                    };
                }
                if (key === 'customer') {
                    return {
                        ...acc,
                        id: apiData[key].id,
                        name: apiData[key].name,
                        email: apiData[key].email,
                        phone: apiData[key].phone,
                        referralCode: apiData[key].referralCode,
                    };
                }

                if (key === 'slots') {
                    const variants = apiData[key]?.reduce((acc: any, slot: any) => [...acc, ...slot.variants], []);

                    const addons = variants?.reduce(
                        (acc: any, variant: any) => [
                            ...acc,
                            ...variant.addons.map((addon: any) => ({
                                ...addon,
                                variantSlug: variant.variantSlug,
                            })),
                        ],
                        [],
                    );

                    const bulkAddons = apiData[key].reduce((acc: any, currentValue: any) => {
                        currentValue?.variants?.forEach((variant: any) => {
                            if (variant.bulkAddon && Object.keys(variant.bulkAddon).length !== 0) {
                                acc.push(variant.bulkAddon);
                            }
                        });
                        return acc;
                    }, []);

                    return {
                        ...acc,
                        slots: apiData[key],
                        addons,
                        variants,
                        bulkAddons,
                    };
                }

                return { ...acc, [key]: apiData[key] };
            }, {});
        }
        case COUPONS_MANAGEMENT: {
            return {
                ...apiData,
                validity: apiData.validity ? moment(apiData.validity, 'YYYY-MM-DD') : '',
            };
        }
    }

    const entityNamesArray = entityName.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        case PROCESSED_PHOTO_ENTITY: {
            return {
                ...apiData,
                documentType: apiData?.documentType,
                photo1: apiData?.photo1?.url,
                photo2: apiData?.photo2?.url,
                photo3: apiData?.photo3?.url,
                photo4: apiData?.photo4?.url,
                processedPhoto: apiData?.processedPhoto?.url,
                processedPhotoGrid: apiData?.processedPhotoGrid?.url,
            };
        }
        default:
            return apiData;
    }
};
