import { errorMsg } from '../constants/messages';

const SignedUpUsersFormData = [
    {
        title: 'Signed-up User Details',
        name: 'name',
        label: 'User Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phone',
        label: 'Phone',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'onboardedFrom',
        label: 'Onboarded From',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Onboarded From' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'active',
        label: 'Active',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'active',
            columnType: 'checkbox',
        },
        isTableHeader: true,
        isAccordian: true,
        default: true,
    },
];

export { SignedUpUsersFormData };
