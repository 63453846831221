/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// Define the props interface
interface ConfirmationModalProps {
    open: boolean;
    message?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    onClose: () => void;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '6px',
    p: 4,
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, message, onConfirm, onCancel, onClose }) => {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
            <Box sx={style}>
                <Typography id='modal-title' variant='h6' component='h2'>
                    {message || 'Are you sure?'}
                </Typography>
                <Box mt={2} display='flex' justifyContent='space-between'>
                    <Button onClick={onConfirm} variant='contained' color='primary'>
                        Confirm
                    </Button>
                    <Button onClick={onCancel || onClose} variant='outlined' color='secondary'>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
