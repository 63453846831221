import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';
import UsersPage from '../UserPage';
import CategoriesPage from '../CategoriesPage';
import SubCategoriesPage from '../SubCategoriesPage';
import ComplaintStatusPage from '../ComplaintStatus';
import LocationPage from '../Location';
import ComplaintsPage from '../ComplaintsPage';
import ManagerTechnician from '../ManagerTechnician';
import AssetsPage from '../Assets';
import LocationTypesPage from '../LocationTypes';
import FaqPage from '../FaqPage';
import AdvantagePage from '../AdvantagePage';
import ReviewsPage from '../ReviewsPage';
import HowItWorkPage from '../HowItWorks';
import PhotomitraAdvantage from '../PhotoMitra/AdvantagePage';
import PhotomitraHowItWork from '../PhotoMitra/HowItWorks';
import PhotomitraTakingPhotoSteps from '../PhotoMitra/TakingPhotoSteps';
import PhotoSizePage from '../PhotoStore/PhotoSize';
import FileTypePage from '../PhotoStore/FileType';
import ContactInfoPage from '../ContactInfo';
import ParameterFormService from '../ApplicationFormMaster/FormParameters';
import ApplicationFormService from '../ApplicationFormMaster/FormServices';
import ApplicationFormQuestionPage from '../ApplicationFormMaster/FormQuestions';
import PackageServiceAttributesFormService from '../ApplicationFormMaster/FormPackageServiceAttributes';
import FormPackageDocuments from '../ApplicationFormMaster/FormPackageDocuments';
import FormPackageFeatures from '../ApplicationFormMaster/FormPackageFeatures';
import ApplicationFormPackagePlans from '../ApplicationFormMaster/FormPackagePlans';
import ApplicationFormPackagesPage from '../ApplicationFormMaster/FormPackagesPage';
import ServiceProcessStepPage from '../ServicesProcess/ProcessSteps';
import ServiceProcessDetailsPage from '../ServicesProcess/DetailsProcess';
import ProcessSubtitlePage from '../ServicesProcess/ProcessSubtitle';
import FormStepsPage from '../ApplicationFormMaster/FormStepsPage';
import ApplicationFormPage from '../ApplicationFormMaster/ApplicationFormPage';
import ContactFormPage from '../ContactFormPage';
import PhotoOrderApplicationPage from '../PhotoOrderApplicationPage';
import DocumitraOrderApplicationPage from '../DocumitraOrderApplicationPage';
import DocumitraTransactionPage from '../DocumitraTransactionPage';
import PhotomitraTransactionPage from '../PhotomitraTransactionPage';
import ApplicationFormPages from '../ApplicationFormPages';
import PhotomitraFaqPage from '../PhotomitraFaqPage';
import CouponsManagement from '../CouponsManagement';
import TermsPage from '../TermsPage';
import ConditionsPage from '../ConditionsPage';
import AboutPageOurTeam from '../AboutPageOurTeam';
import ServiceBasedFAQPage from '../ServiceBasedFAQ';
import PlansPage from '../PhotoStore/PhotoStorePlansPage';
import RelatedArticlePage from '../RelatedArticle';

import './styles.scss';
import ServiceTimelinePage from '../ServiceTimeline/ServiceTimelinePage';
import PackageTimelinePage from '../PackageTimeline/PackageTimelinePage';
import PackageProcessStepPage from '../PackageSteps/PackageProcessStepPage';
import PackageProcessPage from '../PackageProcess/PackageProcessPage';
import ChangePassword from '../ChangePassword';
import SignedUpUsers from '../SignedUpUsers';
import DocumitraLogs from '../DocumitraLogs';
import RefferalDetails from '../RefferalDetails';
import OfferManagement from '../OfferManagement';
import BannerManagement from '../BannerManagement';
import BucketManagement from '../BucketManagement';
import PartnerService from '../PartnerService/PartnerService';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    return (
        <Router>
            <div className='landing-box'>
                <Sidebar title='Documitra' entityArr={moduleList} />
                <div className='landing-box-content'>
                    <Switch>
                        <Route exact path={`${AppRoutes.Users}`}>
                            <UsersPage />
                        </Route>
                        <Route exact path={`${AppRoutes.SignedUpUsers}`}>
                            <SignedUpUsers />
                        </Route>
                        <Route exact path={`${AppRoutes.Faq}`}>
                            <FaqPage />
                        </Route>
                        <Route exact path={`${AppRoutes.Advantage}`}>
                            <AdvantagePage />
                        </Route>
                        <Route exact path={`${AppRoutes.Reviews}`}>
                            <ReviewsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.HowItWorks}`}>
                            <HowItWorkPage />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotomitraAdvantage}`}>
                            <PhotomitraAdvantage />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotomitraHowItWorks}`}>
                            <PhotomitraHowItWork />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotomitraTakingPhotoStep}`}>
                            <PhotomitraTakingPhotoSteps />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotomitraFaq}`}>
                            <PhotomitraFaqPage />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotoStorePhotSize}`}>
                            <PhotoSizePage />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotoStoreFileType}`}>
                            <FileTypePage />
                        </Route>
                        <Route exact path={`${AppRoutes.PhotoStorePlan}`}>
                            <PlansPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ContactInfo}`}>
                            <ContactInfoPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ContactForm}`}>
                            <ContactFormPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormParameters}`}>
                            <ParameterFormService />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormServices}`}>
                            <ApplicationFormService />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormQuestion}`}>
                            <ApplicationFormQuestionPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackageServiceAttributes}`}>
                            <PackageServiceAttributesFormService />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackageDocuments}`}>
                            <FormPackageDocuments />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackageFeatures}`}>
                            <FormPackageFeatures />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackagePlans}`}>
                            <ApplicationFormPackagePlans />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormPackages}`}>
                            <ApplicationFormPackagesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ServiceProcessSteps}`}>
                            <ServiceProcessStepPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ServiceProcessDetailsProcess}`}>
                            <ServiceProcessDetailsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ServiceProcessSubtitle}`}>
                            <ProcessSubtitlePage />
                        </Route>
                        <Route exact path={`${AppRoutes.ServiceTimeline}`}>
                            <ServiceTimelinePage />
                        </Route>
                        <Route exact path={`${AppRoutes.PackageTimeline}`}>
                            <PackageTimelinePage />
                        </Route>
                        <Route exact path={`${AppRoutes.PackageProcess}`}>
                            <PackageProcessPage />
                        </Route>
                        <Route exact path={`${AppRoutes.PackageProcessSteps}`}>
                            <PackageProcessStepPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationFormSteps}`}>
                            <FormStepsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.ApplicationForm}`}>
                            <ApplicationFormPage />
                        </Route>
                        <Route exact path={`${AppRoutes.CouponsManagement}`}>
                            <CouponsManagement />
                        </Route>
                        <Route exact path={`${AppRoutes.OfferManagement}`}>
                            <OfferManagement />
                        </Route>
                        <Route exact path={`${AppRoutes.BannerManagement}`}>
                            <BannerManagement />
                        </Route>
                        <Route exact path={`${AppRoutes.PartnerService}`}>
                            <PartnerService />
                        </Route>
                        <Route exact path={`${AppRoutes.BucketManagement}`}>
                            <BucketManagement />
                        </Route>
                        <Route exact path={`${AppRoutes.TermsConditionTerms}`}>
                            <TermsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.TermsConditionConditions}`}>
                            <ConditionsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.AboutPageOurTeam}`}>
                            <AboutPageOurTeam />
                        </Route>
                        <Route exact path={`${AppRoutes.ServiceBasedFAQ}`}>
                            <ServiceBasedFAQPage />
                        </Route>
                        <Route exact path={`${AppRoutes.RelatedArticle}`}>
                            <RelatedArticlePage />
                        </Route>
                        <Route exact path={`${AppRoutes.DocumitraLogs}`}>
                            <DocumitraLogs />
                        </Route>
                        <Route exact path={`${AppRoutes.RefferalDetails}`}>
                            <RefferalDetails />
                        </Route>
                        <Route exact path={`${AppRoutes.Categories}`}>
                            <CategoriesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.Subcategories}`}>
                            <SubCategoriesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.complainStatus}`}>
                            <ComplaintStatusPage />
                        </Route>
                        <Route exact path={`${AppRoutes.locationType}`}>
                            <LocationTypesPage />
                        </Route>
                        <Route exact path={`${AppRoutes.locations}`}>
                            <LocationPage />
                        </Route>
                        <Route exact path={`${AppRoutes.complaintsPage}`}>
                            <ComplaintsPage />
                        </Route>
                        <Route exact path={`${AppRoutes.managerTechnician}`}>
                            <ManagerTechnician />
                        </Route>
                        <Route exact path={`${AppRoutes.assets}`}>
                            <AssetsPage />
                        </Route>
                        <Route exact path={AppRoutes.PhotoOrderApplication}>
                            <ApplicationFormPages />
                        </Route>
                        <Route path={`${AppRoutes.PhotoOrderApplication}/:id`}>
                            <ApplicationFormPages />
                        </Route>
                        <Route exact path={AppRoutes.DocumitraOrderApplication}>
                            <DocumitraOrderApplicationPage />
                        </Route>
                        <Route exact path={AppRoutes.DocumitraOrderTransaction}>
                            <DocumitraTransactionPage />
                        </Route>
                        <Route exact path={AppRoutes.PhotoOrderTransaction}>
                            <PhotomitraTransactionPage />
                        </Route>
                        <Route path={`${AppRoutes.DocumitraOrderApplication}/:id`}>
                            <DocumitraOrderApplicationPage />
                        </Route>
                        <Route path={`${AppRoutes.Stores}/:id/store-variant-price`}>
                            <PhotoOrderApplicationPage />
                        </Route>
                        <Route path={`${AppRoutes.Stores}/:id/store-add-ons`}>
                            <PhotoOrderApplicationPage />
                        </Route>
                        <Route path={`${AppRoutes.Stores}/:id/store-bulk-add-ons`}>
                            <PhotoOrderApplicationPage />
                        </Route>
                        <Route path={`${AppRoutes.Stores}/:id/slots`}>
                            <PhotoOrderApplicationPage />
                        </Route>
                        <Route path={`${AppRoutes.ChangePassword}`}>
                            <ChangePassword />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default Landing;
