/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;
import { getStoreId } from '../../service/CookieManager';
import { reportType } from '../../constants/AppConstant';
import callApi, { Method } from '../../Api/config';
import {
    CONTACT_FORM,
    DOCUMITRA_ORDER_APPLICATION,
    DOCUMITRA_ORDER_TRANSACTION,
    ORDER_USER,
    PHOTO_ORDER_APPLICATION,
    PHOTO_ORDER_TRANSACTION,
} from '../../constants/EntityName';

import './style.scss';
import { toastError, toastSuccess } from '../../service/Toast';

interface IDateRange {
    startDate: string;
    endDate: string;
    storeId?: string;
}

interface IDownlaodProps {
    entityName: string;
}

const DownloadData = (props: IDownlaodProps) => {
    const { entityName } = props;
    const userStoreId = getStoreId() || null;
    const [dateError, setDateError] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<IDateRange>({
        startDate: '',
        endDate: '',
        storeId: '',
    });

    let pdfTitle = '';

    const isDateDisabled = (current: moment.Moment | null) => (current ? current > moment().endOf('day') : false);

    const dateSelector = (dateRangeArray: any) => {
        if (dateRangeArray && dateRangeArray.length >= 2) {
            const startDateRange = dateRangeArray[0]?.toISOString();
            const endDateRange = dateRangeArray[1]?.toISOString();
            setDateRange({
                endDate: endDateRange,
                startDate: startDateRange,
                ...(userStoreId && { storeId: userStoreId }),
            });
        } else {
            console.error('Date range array is null or does not have enough elements.');
        }
    };

    const downloadListData = (response: any) => {
        if (response?.status) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${reportType[entityName]}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setDateError(false);
        }
    };

    function downloadListDataPDF(response: any) {
        if (response?.status) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;

            // Convert XLSX data to PDF
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    const data = new Uint8Array(e.target.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    const headers: string[] = jsonData[0].map((header: any) => String(header));
                    const rows: any[][] = jsonData.slice(1) as any[][];

                    const pdfWidth = 450;
                    let pdfHeight = 1100;

                    if (entityName === PHOTO_ORDER_APPLICATION) {
                        pdfTitle = 'Photomitra Order Details';
                    } else if (entityName === DOCUMITRA_ORDER_APPLICATION) {
                        pdfTitle = 'Documitra Order Details';
                    } else if (entityName === ORDER_USER) {
                        pdfTitle = 'Documitra Users Details';
                    } else if (entityName === CONTACT_FORM) {
                        pdfHeight = 1300;
                        pdfTitle = 'Documitra Contact Details';
                    } else if (entityName === PHOTO_ORDER_TRANSACTION) {
                        pdfTitle = 'Photomitra Order Transactions';
                    } else if (entityName === DOCUMITRA_ORDER_TRANSACTION) {
                        pdfTitle = 'Documitra Order Transactions';
                    }

                    const pdf = new jsPDF({
                        orientation: 'landscape',
                        unit: 'pt',
                        format: [pdfWidth, pdfHeight],
                    });
                    pdf.setFontSize(7);

                    // Set maximum column width to 60 characters
                    const maxColumnWidth = getTextWidth(pdf, 'X'.repeat(50));
                    const columnWidths = headers.map((header, colIndex) => {
                        const headerWidth = getTextWidth(pdf, header);
                        const maxRowWidth = rows.reduce((maxWidth, row) => {
                            const cellWidth = getTextWidth(pdf, String(row[colIndex] || '--'));
                            return Math.min(maxColumnWidth, Math.max(maxWidth, cellWidth));
                        }, 0);
                        return Math.max(headerWidth, maxRowWidth) + 30; // Adding padding
                    });

                    // Ensure all rows have the same number of columns, filling missing values with '--'
                    const maxColumns = Math.max(...rows.map((row) => row.length));
                    const formattedRows = rows.map((row) => {
                        const newRow = Array(maxColumns).fill('--');
                        row.forEach((cell, index) => {
                            newRow[index] = cell !== undefined ? cell : '--';
                        });
                        return newRow;
                    });

                    // Define table dimensions
                    const startX = 20;
                    const startY = 20;
                    const cellHeight = 15;
                    const lineHeight = 10;
                    const headerMargin = 35;
                    // const pdfWidth = pdf.internal.pageSize.getWidth();

                    let currentPage = 1;
                    let currentY = startY;

                    // Draw title text at the top of the PDF
                    const titleText = pdfTitle;
                    const titleX = pdfHeight / 3;
                    pdf.setFontSize(20);
                    pdf.text(titleText, titleX, currentY, { align: 'center' });

                    currentY += cellHeight;

                    drawTableHeaders(pdf, headers, columnWidths, startX, currentY, cellHeight, lineHeight);

                    currentY += headerMargin;

                    // Draw table rows with pagination
                    formattedRows.forEach((row, rowIndex) => {
                        if (rowIndex > 0 && rowIndex % 10 === 0) {
                            // Change to 10 rows per page
                            pdf.addPage();
                            currentPage++;
                            currentY = startY;
                            drawTableHeaders(pdf, headers, columnWidths, startX, currentY, cellHeight, lineHeight);
                            currentY += headerMargin;
                        }
                        currentY = drawTableRow(
                            pdf,
                            row,
                            columnWidths,
                            startX,
                            currentY,
                            headers,
                            cellHeight,
                            lineHeight,
                        );
                    });

                    const pdfBlob = pdf.output('blob');
                    const pdfUrl = URL.createObjectURL(pdfBlob);

                    a.href = pdfUrl;
                    a.download = `${reportType[entityName]}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                    setDateError(false);
                } else {
                    console.error('Error reading file data.');
                }
            };
            reader.readAsArrayBuffer(response.data);
        }
    }

    function drawTableHeaders(
        pdf: any,
        tableHeader: string[],
        columnWidths: number[],
        startX: number,
        currentY: number,
        lineHeight: number,
        cellHeight: number,
    ) {
        pdf.setFontSize(7);
        pdf.setFont('helvetica', 'bold');
        pdf.setFillColor(200, 200, 200);
        pdf.rect(startX, currentY + lineHeight, getTableWidth(columnWidths), cellHeight + 6, 'F');
        tableHeader.forEach((header, index) => {
            const cellWidth = columnWidths[index];
            const headerX = startX + getPreviousCellWidth(columnWidths, index) + 2;
            const headerY =
                currentY +
                lineHeight * 1.6 +
                (cellHeight - pdf.internal.getLineHeight() / pdf.internal.scaleFactor) / 2;
            pdf.text(header, headerX, headerY);
        });
    }

    function drawTableRow(
        pdf: any,
        row: any[],
        columnWidths: number[],
        startX: number,
        currentY: number,
        headers: string[],
        lineHeight: number,
        cellHeight: number,
    ) {
        let currentX = startX;
        let maxCellHeight = cellHeight;

        // Calculate the maximum cell height and adjust cell width dynamically
        row.forEach((cell, cellIndex) => {
            const cellText = cell === undefined ? '--' : String(cell);
            const cellWidth = Math.min(getTextWidth(pdf, cellText) + 8, columnWidths[cellIndex]); // Dynamic cell width

            // Calculate text lines and height
            const lines = splitTextIntoLines(cellText, cellWidth, pdf);
            const cellTextHeight = lines.length * lineHeight;
            maxCellHeight = Math.max(maxCellHeight, cellTextHeight);

            // Update the column width if needed
            columnWidths[cellIndex] = Math.max(columnWidths[cellIndex], cellWidth);
        });

        // Draw cells with the maximum height and adjusted width
        row.forEach((cell, cellIndex) => {
            const cellText = cell === undefined ? '--' : String(cell);
            const cellWidth = columnWidths[cellIndex];

            // Calculate text lines and height
            const lines = splitTextIntoLines(cellText, cellWidth, pdf);
            const cellTextHeight = lines.length * lineHeight;

            // Calculate the text position to center it vertically in the cell
            const textY = currentY + (maxCellHeight - cellTextHeight) / 2 + lineHeight / 2;

            // Draw cell with the maximum height and adjusted width
            pdf.rect(currentX, currentY, cellWidth, maxCellHeight);

            // Add padding to text content in all directions
            const paddingX = 2; // Padding from left
            const paddingY = 1; // Padding from top
            lines.forEach((line, lineIndex) => {
                const textX = currentX + paddingX;
                const textYLine = textY + lineIndex * lineHeight + paddingY;
                pdf.text(line, textX, textYLine);
            });

            currentX += cellWidth;
        });

        return currentY + maxCellHeight;
    }

    function getTextWidth(pdf: any, text: string) {
        return (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
    }

    function splitTextIntoLines(text: string, maxWidth: number, pdf: any): string[] {
        const words = text.split(' ');
        const lines = [];
        let currentLine = '';

        words.forEach((word) => {
            const testLine = currentLine ? `${currentLine} ${word}` : word;
            if (getTextWidth(pdf, testLine) <= maxWidth) {
                currentLine = testLine;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        });

        if (currentLine) {
            lines.push(currentLine);
        }

        return lines;
    }

    function getTableWidth(columnWidths: number[]): number {
        return columnWidths.reduce((totalWidth, width) => totalWidth + width, 0);
    }

    function getPreviousCellWidth(columnWidths: number[], index: number): number {
        return columnWidths.slice(0, index).reduce((totalWidth, width) => totalWidth + width, 0);
    }

    const handleDownloadClick = async (entityName: string) => {
        const requestData = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            storeId: dateRange.storeId,
        };

        try {
            const response: any = await callApi(`/api/reports/${entityName}`, 'POST', requestData, {
                responseType: 'arraybuffer',
            });
            const contentType = response.headers['content-type'];
            if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                downloadListData(response);
            } else if (contentType === 'application/pdf') {
                downloadListDataPDF(response);
            } else {
                console.error('Unsupported content type:', contentType);
            }
        } catch (error) {
            console.error('Error downloading data:', error);
        }
    };

    const generateReport = async () => {
        if (!dateRange.endDate || !dateRange.startDate) {
            setDateError(true);
            return;
        }
        let res;
        if (entityName) {
            res = await callApi(
                `/admin/download-reports`,
                'GET',
                null,
                { startDate: dateRange.startDate, endDate: dateRange.endDate, reportType: reportType[entityName] },
                {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                Method.DOWNLOAD,
            );
            if (res?.status == false) {
                toastError(`Date range cannot exceed three month.`);
            } else {
                toastSuccess(`Download will start shortly`);
            }
        }
        downloadListData(res);
    };

    const generateReportPDF = async () => {
        if (!dateRange.endDate || !dateRange.startDate) {
            setDateError(true);
            return;
        }
        let res;
        if (entityName) {
            res = await callApi(
                `/admin/download-reports`,
                'GET',
                null,
                { startDate: dateRange.startDate, endDate: dateRange.endDate, reportType: reportType[entityName] },
                {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                Method.DOWNLOAD,
            );
        }
        downloadListDataPDF(res);
    };

    return (
        <div className='mainContainer'>
            <div className='rangeContainer' style={{ marginLeft: '10px' }}>
                <RangePicker
                    size='small'
                    ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    onChange={dateSelector}
                    disabledDate={isDateDisabled}
                />
                <p
                    id='errorText'
                    style={{
                        color: 'red',
                        visibility: dateError ? 'visible' : 'hidden',
                    }}>
                    Please select the date range.
                </p>
            </div>
            <div className='errorText'>
                <button className='formDownloadBtn' onClick={generateReport}>
                    Download Excel
                </button>
                <button className='formDownloadBtn' onClick={generateReportPDF}>
                    Download PDF
                </button>
            </div>
        </div>
    );
};

export default DownloadData;
